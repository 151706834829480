import logo from './logo.svg';
import './App.css';

import Auth from './pages/Auth';
import Guest from './pages/Guest';

import { 
  useSelector,
  useDispatch 
} from 'react-redux';

function App() {
  const {isLoggedIn,session} = useSelector((state)=>{ console.log(state);return state.auth; })
  const message = useSelector((state)=>{ return state.messge})
  return (
    <div className="App">
        {
          isLoggedIn?
          <Auth />:
          <Guest />
        }
    </div>
  );
}

export default App;
