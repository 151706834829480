import React,{useEffect,useState} from 'react';
import { formValidateField } from '../../../helpers/formvalidation'; 
import LocalMessage from '../../../components/LocalMessage';

const RequestPasswordForm = (props) => {
    const {
        onSubmit,
        onBack
    } = props
    
    const formInitialState = {
        "email":{
            "value":"",
            "name":"email",
            "error":""
        },       
    }

    const [form, setForm] = useState(formInitialState);
    const [loading, setLoading] = useState(false);
    const [message,setMessage] = useState({
        value:"",
        type:""
    });
    

    const handleFormChange = (e)=>{
        const {
            name,
            value
        } = e.target
        
        setForm({
            ...form,
            [name]:{
                ...form[name],
                value
            }
        })
    }

    const handleBack = (e) =>{        
        if(onBack){
            onBack()
        }
    }

    const handleSubmit = (e)=>{
        e.preventDefault()
        setMessage({
            value:"",
            type:""
        })
        setLoading(true)
        const {
            email,            
        } = form
        const formToValidate = {
            "email":{
                ...email,                
                "error":""
            },
        }
        let valid = true

        if(!formValidateField(email.value,'required')){
            formToValidate = {
                ...formToValidate,
                'email':{
                    ...email,
                    "error":"The email is required"
                }
            }
            valid = false
        }
        if(!formValidateField(email.value,'email')){
            formToValidate = {
                ...formToValidate,
                'email':{
                    ...email,
                    "error":"The email is not valid"
                }
            }
            valid = false
        }       
        
        if(valid){
            if(onSubmit){
                onSubmit(
                    {
                        "email":email.value,                        
                    },
                    (success)=>{
                        setLoading(false)
                        setMessage({
                            "value":success,
                            "type":"success"
                        })
                    },
                    (error)=>{
                        setLoading(false)
                        setMessage({
                            "value":error,
                            "type":"error"
                        })
                    }
                )
            }
        }else{
            setLoading(false)
            setForm(formToValidate)
        }
    }

    return (        
        <form onSubmit={handleSubmit}>
            <div className="form-group row">
                <div className="col-12 text-center">                   
                    <h4>
                        Recover Password
                    </h4>
                </div>
            </div>
            <div className="form-group my-1">
                <label htmlFor="email">Email</label>
                <input 
                    name='email' 
                    placeholder='email' 
                    className='form-control form-control-sm' 
                    type="email" 
                    onChange={handleFormChange} 
                />
                {form.email.error!==""&&<p className='text-danger'>{form.email.error}</p>}
            </div>          
            <div className="row justify-content-end">
                <div className="col-auto">
                    <button
                        className='btn-sm btn btn-link'
                        type='button'
                        onClick={handleBack}
                    >
                        login
                    </button>                    
                </div>
            </div>

            <div className="row my-1">
                <div className="col-12">
                    <p>
                        <LocalMessage 
                            message={message.value}
                            type={message.type}
                        />
                    </p>
                </div>
            </div>



            <div className="form-group row justify-content-center">
                <div className="col-auto">
                    <button 
                        disabled={loading}
                        className="btn-sm btn btn-secondary" 
                        type='submit'                        
                        >
                        {!loading?"Send": <span> <i className="fa fa-spinner fa-spin"></i> loading... </span> }
                    </button>
                </div>
            </div>
        </form>        
    );
}

export default RequestPasswordForm;
