import React from 'react';

const PlateContainer = (props) => {
    const {
        plate,
        onClick
    }= props

    return (
        <div className='w-100 border rounded border-white border-accent overflow-hidden cursor-pointer position-relative bg-gray card-plate' onClick={()=>{onClick(plate)}}>            
            <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                <h4 className='text-center text-white'>{plate?plate:"No plate"}</h4>
            </div>
        </div>
    );
}

export default PlateContainer;

