import React,{useState,useEffect} from 'react';

const RidesSearchNav = (props) => {
    
    const {
        onChange,
        searchParams
    }= props


    const [search, setSearch] = useState(searchParams);
    const [searching, setSearching] = useState(false);
    const [firstSearchCall, setFirstSearchCall] = useState(true);

    const handleSearchChange = (input,value)=>{        
        setSearch({
            ...search,
            [input]:value
        })
    }
    const handleChange = ()=>{
        console.log('searching on')
        if(onChange){
            setSearching(true)            
            onChange(
                search,
                ()=>{   
                    console.log('no')
                    setSearching(false)
                }
            )
        }
    }

    useEffect(() => {
        console.log('change')
        if(firstSearchCall){
            setFirstSearchCall(false)
        }else{                                         
            const timeout = setTimeout(() => {   
                setSearching(true)   
                handleChange()
            }, 900)
            return () => clearTimeout(timeout)
        }                
    }, [search.user])

    useEffect(() => {        
        handleChange()
    }, [search.fromDate,search.toDate,search.active,search.payment]);

    return (
        <div className='row align-items-center py-3'>   
            <div className="col">
                <input 
                    type="text"
                    className="form-control form-control-sm"
                    disabled={searching}
                    value={search.user}
                    onChange={(e)=>{handleSearchChange('user',e.target.value)}}
                    />
            </div>
            <div className="col">
                <input 
                    type="date" 
                    className="form-control form-control-sm"
                    disabled={searching} 
                    value={search.fromDate}
                    onChange={(e)=>{handleSearchChange('fromDate',e.target.value)}}
                    />
            </div>
            <div className="col">
                <input 
                    type="date" 
                    className="form-control form-control-sm"
                    disabled={searching} 
                    value={search.toDate}
                    onChange={(e)=>{handleSearchChange('toDate',e.target.value)}}
                    />
            </div>
            <div className={`col-auto cursor-pointer ${searching&&'disabled'}`} onClick={(e)=>{handleSearchChange('active',!search.active)}}>
                <h6 className='no-margin'>
                    <i className={`me-1 ${search.active?"fa fa-toggle-on text-primary":"fa fa-toggle-off"}`}></i><b className={`${search.active?"text-primary":"text-muted"}`}>Active</b>
                </h6>                
            </div>
            <div className={`col-4 ${searching&&"disabled"}`}>
                <div className="form-check form-check-inline">
                    <input 
                        className="form-check-input" 
                        type="radio" 
                        name="payment" 
                        id="paymentRadioAll" 
                        value=""
                        disabled={searching}
                        onChange={()=>{handleSearchChange('payment','*')}}
                        checked={search.payment=="*"}  />
                    <label className="form-check-label" htmlFor="paymentRadioAll">All</label>
                </div>
                <div className="form-check form-check-inline">
                    <input 
                        className="form-check-input" 
                        type="radio" 
                        name="payment" 
                        id="paymentRadioCard" 
                        value=""
                        disabled={searching}
                        onChange={()=>{handleSearchChange('payment','card')}}
                        checked={search.payment=="card"} />
                    <label className="form-check-label" htmlFor="paymentRadioCard">Credit Card</label>
                </div>
                <div className="form-check form-check-inline">
                    <input 
                        className="form-check-input" 
                        type="radio" 
                        name="payment" 
                        id="paymentRadioCash" 
                        value=""
                        disabled={searching}
                        onChange={()=>{handleSearchChange('payment','cash')}}
                        checked={search.payment=="cash"} />
                    <label className="form-check-label" htmlFor="paymentRadioCash">Cash</label>
                </div>
                <div className="form-check form-check-inline">
                    <input 
                        className="form-check-input" 
                        type="radio" 
                        name="payment" 
                        id="paymentRadioCash" 
                        value=""
                        disabled={searching}
                        onChange={()=>{handleSearchChange('payment','report')}}
                        checked={search.payment=="report"} />
                    <label className="form-check-label" htmlFor="paymentRadioCash">Only reported?</label>
                </div>
            </div>
        </div>
    );
}

export default RidesSearchNav;
