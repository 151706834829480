import React from 'react';
import { Routes,Route } from 'react-router-dom';

import Login from '../Login';
import RecoverPassword from '../RecoverPassword';

const Guest = () => {
    return (
        <div>
            <Routes>
                <Route path='/' element={<Login />}>
                    
                </Route>
                <Route path='/password-reset/:token' element={ <RecoverPassword  /> } />
            </Routes>            
        </div>
    );
}

export default Guest;
