import React,{useState,useEffect} from 'react';
import { useDispatch,useSelector } from 'react-redux';

import * as appActions from '../../actions/app.actions'
import * as ridesActions from '../../actions/rides.actions'

import PassengerListItem from './components/PassengerListItem';
import PassengerSearchNav from './components/PassengerSearchNav';

import CRMModal from '../../components/CRMModal';
import UserReportsNames from '../../components/UserReportsNames';
import FormatedDate from '../../components/FormatedDate';

import BasicList from '../../components/BasicList';
import PaginationFooter from '../../components/PaginationFooter';

import { useNavigate } from 'react-router-dom';

const Passengers = (props) => {    
    const {

    }=props

    const navigate =  useNavigate()

    const dispatch = useDispatch()

    const searchParamsInitialState = {
        "user":"",
        "fromDate":"",
        "toDate":"",
        "enabled":true,
        "onlyReported":false
    }
    const paginationInitialState = {
        "currentPage":1,
        "totalPages":1
    }    

    
    const [searchParams, setSearchParams] = useState(searchParamsInitialState);
    const [pagination, setPagination] = useState(paginationInitialState);
    const [showReports, setShowReports] = useState([]);
    
    const [list, setList] = useState({
        "list":[],
        "loading":false
    });

    const getApi = (params,page,onFinish=(param)=>{console.log(param)}) => {
        const {
            user,
            fromDate,
            toDate,
            enabled,
            onlyReported
        } = params
        

        setList({
            ...list,
            'loading':true
        })

        dispatch(ridesActions.getPassengers(
            user,
            fromDate,
            toDate,
            enabled?1:0,
            onlyReported?1:0,
            page
        )).then(
            (response)=>{
                console.log()
                setList({
                    'list':response.data,
                    'loading':false
                })
                //TODO total pages
                setPagination({
                    ...pagination,
                    "totalPages":page
                })
                onFinish(true)
            },
            (error)=>{
                //TODO handle Error
                setList({
                    ...list,
                    'loading':false
                })
            }
        )
    }

    const handleUserClick = (model)=>{
        console.log("Open passenger",model)
        navigate('/passenger/'+model.id)
    }

    const handleShowReports = (reports)=>{
        setShowReports(reports)        
    }

    useEffect(() => {
        dispatch(
            appActions.setNav("PASSENGERS")
        )
        getApi(searchParams,1,(p)=>{console.log('First call')})
    }, []);


    return (
        <div className='row col-12 justify-content-center'>
            {
                showReports.length>0&&
                <CRMModal
                    title="Passenger reports"
                    onClose={()=>{
                        setShowReports([])
                    }}
                >
                    <div>
                        <ol>
                            {showReports.map((item)=>{
                                return <li>                                            
                                    <p className='text-start'><small><b><UserReportsNames id={item.report_type}/></b>: {item.comment}</small></p>
                                    <p className="text-end">
                                        <small>
                                            <i><FormatedDate type="basic">{item.report_date}</FormatedDate></i>
                                        </small>
                                    </p>
                                </li>
                            })} 
                        </ol>
                    </div>
                </CRMModal>
            }
            <PassengerSearchNav 
                searchParams={searchParams}
                onChange={(params,onFinish)=>{
                    console.log('search params',params)
                    setSearchParams({
                        ...searchParams,
                        ...params                
                    })
                    getApi(
                        params,
                        1,
                        onFinish
                    )                    
                }}                
            />
            <BasicList 
                list={list.list}
                loading={list.loading}
                noDataText={"No passengers"}
                loadingText={"loading passenger..."}
                renderFunction={(model)=>{
                    return <PassengerListItem 
                        key={model.id}
                        model={model}
                        onShowReports={handleShowReports}
                        onUserClick={handleUserClick}                       
                    />
                }}
            />
            <PaginationFooter
                currentPage={pagination.currentPage}
                totalPages={pagination.totalPages}
                onNext={(page)=>{
                    setPagination({
                        ...pagination,
                        "currentPage":page
                    })
                    getApi(
                        searchParams,
                        page,
                        ()=>{
                            console.log('pageNext',page)
                        }
                    )

                }}
                onPrev={(page)=>{
                    setPagination({
                        ...pagination,
                        "currentPage":page
                    })
                    getApi(
                        searchParams,
                        page,
                        ()=>{
                            console.log('pagePrev',page)
                        }
                    )
                }}
            />
        </div>
    );
}

export default Passengers;
