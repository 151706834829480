import React,{useState,useEffect} from 'react';

const DriversSearchNav = (props) => {
    
    const {
        onChange,
        searchParams
    }= props


    const [search, setSearch] = useState(searchParams);
    const [searching, setSearching] = useState(false);
    const [firstSearchCall, setFirstSearchCall] = useState(true);

    const handleSearchChange = (input,value)=>{
        if(input=="enabled" && value==2){
            setSearch({
                ...search,
                [input]:value,
                'onlyReported':false
            })

            return
        }
        setSearch({
            ...search,
            [input]:value
        })
    }
    const handleChange = ()=>{
        if(onChange){
            setSearching(true)            
            onChange(
                search,
                ()=>{   
                    setSearching(false)
                }
            )
        }
    }

    useEffect(() => {
        if(firstSearchCall){
            setFirstSearchCall(false)
        }else{  
            const timeout = setTimeout(() => {    
                setSearching(true)                             
                handleChange()
            }, 900)
            return () => clearTimeout(timeout)
        }                
    }, [search.user])

    useEffect(() => {        
        handleChange()
    }, [search.fromDate,search.toDate,search.enabled,search.onlyReported]);

    return (
        <div className='row py-3'>   
            <div className="col">
                <input 
                    type="text" 
                    className="form-control form-control-sm"
                    disabled={searching} 
                    value={search.user}
                    onChange={(e)=>{handleSearchChange('user',e.target.value)}}
                    />
            </div>
            <div className="col">
                <input 
                    type="date" 
                    className="form-control form-control-sm"
                    disabled={searching} 
                    value={search.fromDate}
                    onChange={(e)=>{handleSearchChange('fromDate',e.target.value)}}
                    />
            </div>
            <div className="col">
                <input 
                    type="date" 
                    className="form-control form-control-sm"
                    disabled={searching} 
                    value={search.toDate}
                    onChange={(e)=>{handleSearchChange('toDate',e.target.value)}}
                    />
            </div>   

            <div className="col-3">                
                <div className="form-check form-check-inline">
                    <input 
                        className="form-check-input" 
                        type="radio" 
                        name="enabledRadio" 
                        id="enabledRadioEnabled" 
                        value=""
                        onChange={()=>{handleSearchChange('enabled',1)}}
                        checked={search.enabled==1} />
                    <label className="form-check-label" htmlFor="enabledRadioEnabled">Enabled</label>
                </div>
                <div className="form-check form-check-inline">
                    <input 
                        className="form-check-input" 
                        type="radio" 
                        name="enabledRadio" 
                        id="enabledRadioDisabled" 
                        value=""
                        onChange={()=>{handleSearchChange('enabled',0)}}
                        checked={search.enabled==0} />
                    <label className="form-check-label" htmlFor="enabledRadioDisabled">Disabled</label>
                </div>
                <div className="form-check form-check-inline">
                    <input 
                        className="form-check-input" 
                        type="radio" 
                        name="enabledRadio" 
                        id="enabledRadioDisabled" 
                        value=""
                        onChange={()=>{handleSearchChange('enabled',2)}}
                        checked={search.enabled==2} />
                    <label className="form-check-label" htmlFor="enabledRadioDisabled">Pending</label>
                </div>
            </div>            
            <div className={`col-auto ${search.enabled==2&&"d-none"}`}>
                |
            </div>
            <div className={`col text-center ${search.enabled==2&&"d-none"}`}>

                <div className="form-check form-check-inline">
                    <input 
                        className="form-check-input" 
                        type="checkbox" 
                        name="onlyReported" 
                        id="onlyReportedCheckBox"                         
                        onChange={()=>{handleSearchChange('onlyReported',!search.onlyReported)}}
                        checked={search.onlyReported} />
                    <label className="form-check-label" htmlFor="onlyReported">Only Reported?</label>
                </div>
            </div>
        </div>
    );
}

export default DriversSearchNav;
