import React from 'react';
import { useNavigate } from 'react-router-dom';

const SectionButton = (props) => {
    const {
        title,
        icon="",
        to="",
        extraClass=""
    }= props

    const navigate = useNavigate()

    return (        
        <button className={`btn btn-primary p-3 ${extraClass!==""&&extraClass}`} onClick={()=>{navigate(to)}} >
            {icon!==""&&icon}
            {title}
        </button>        
    );
}

export default SectionButton;
