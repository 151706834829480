import React from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import logo from '../assets/logo.jpg'

import * as authActions from '../actions/auth.actions'

const NavBar = (props) => {
    const nav = useSelector((state)=>( state.navbar.index ))
    const navigation = useNavigate()
    const dispatch = useDispatch()

    const handleClick = (to)=>{
        navigation(to)
    }
    const handleLogout = ()=>{
        dispatch(
            authActions.logout()
        ).then(
            ()=>{
                navigation('/')            
            },
            ()=>{
                navigation('/')
            }
        )
    }

    return (
        <div className='crm-navbar'>
            <div className="container">
                <div className="row justify-content-between align-items-center">
                    <div className="col row justify-content-start align-items-center">                        
                        <div className="col-auto cursor-pointer" onClick={()=>{ handleClick('/') }}>
                            <img src={logo} alt="" className='logo'/>
                            {/* <button  onClick={()=>{ handleClick('/') }} 
                                className={`btn-sm btn btn-link ${nav=="HOME"&&"btn-link--active"}`}>
                                BR Admin Home
                            </button> */}
                        </div>
                        <div className="col-auto">
                            <button onClick={()=>{ handleClick('/rides') }} 
                                className={`btn-sm btn btn-link ${nav=="RIDES"&&"btn-link--active"}`}>
                                Rides
                            </button>
                        </div>
                        <div className="col-auto">
                            <button onClick={()=>{ handleClick('/passengers') }} 
                                className={`btn-sm btn btn-link ${nav=="PASSENGERS"&&"btn-link--active"}`}>
                                Passengers
                            </button>
                        </div>
                        <div className="col-auto">
                            <button onClick={()=>{ handleClick('/drivers') }} 
                                className={`btn-sm btn btn-link ${nav=="DRIVERS"&&"btn-link--active"}`}>
                                Drivers
                            </button>
                        </div>
                        <div className="col-auto">
                            <button onClick={()=>{ handleClick('/landingemails') }} 
                                className={`btn-sm btn btn-link ${nav=="EMAILS"&&"btn-link--active"}`}>
                                Emails from landing
                            </button>
                        </div>
                        <div className="col-auto">
                            <button onClick={()=>{ handleClick('/landingcontact') }} 
                                className={`btn-sm btn btn-link ${nav=="CONTACT"&&"btn-link--active"}`}>
                                Contact Emails from landing
                            </button>
                        </div>
                        {/* <div className="col-auto">
                            <button onClick={()=>{ handleClick('/reports') }} 
                                className={`btn-sm btn btn-link ${nav=="REPORTS"&&"btn-link--active"}`}>
                                Reports
                            </button>
                        </div>
                        <div className="col-auto">
                            <button onClick={()=>{ handleClick('/stats') }} 
                                className={`btn-sm btn btn-link ${nav=="STATS"&&"btn-link--active"}`}>
                                Stats
                            </button>
                        </div> */}
                    </div>
                    <div className="col-auto">
                        <button className="btn-sm btn btn-link" onClick={()=>{ handleLogout() }} >
                            Logout
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default NavBar;
