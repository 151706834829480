import * as T from "../actions/types";

const errorlist = {
    "0101":"Can't login. Email or password are incorrect",
    "0102":"Can't logout",
    "1001":"Can't make the reset password request",
    "1002":"Can't update the user password",
    "1003":"Can't make the reset password. The email wasn't found",
    "0201":"Can't add the configuration",
    "0202":"Can't remove the configuration",
    "0203":"Can't update the configuration",
    "0204":"Can't list the configurations",
    "0205":"Can't get the config",
    "0206":"Can't add the configuration. The config key already exist",
    "0301":"Can't add the tag",
    "0302":"Can't remove the tag",
    "0303":"Can't update the tag",
    "0304":"Can't list the tags",
    "0305":"Can't get the tag",
    "0401":"Can't add the category",
    "0402":"Can't remove the category",
    "0403":"Can't update the category",
    "0404":"Can't list the categories",
    "0405":"Can't get the category",
    "0501":"Can't get the conversation list",
    "0601":"Can't get the message list",
    "0701":"Can't get the payments info",
    "0801":"Can't get the service count",
    "0802":"Can't get the app information",
    "0803":"Can't list the services",
    "0804":"Can't get the service information",
    "0901":"Can't get the users count",
    "0903":"Can't get the users list",
    "0904":"can't get the user information",
    "0905":"Can't get the user reviews",
    "0906":"Can't get the user conversation list",
    "0907":"Can't get the gurus list for upgrade",
    "0908":"Can't upgrade the guru",
    "0909":"Can't update the user information",
    "0910":"Can't change the user status",
    "0911":"Unable to send the mail",
    "0912":"Can't send the mails to users",
    "0913":"Can't send the notification to user",
}

export const errorhandler = (error,dispatch,shouldReturn=false) => {
    console.log(error)
    if(error == undefined){
        if(shouldReturn){
            return "Ups! something went wrong. Please try again later"
        }
        dispatch({
                type: T.SET_APP_ERROR_MESSAGE,
                payload: "Ups! something went wrong. Please try again later",
        })
        return
    }   
    const {response} = error
    const {status,data} = response    

    switch(status){
        case 401:
            if(shouldReturn){
                return "Unathourized: You don't have the permissons to make this operation"
            }
            dispatch({
                type:T.LOGOUT
            })
        break
        case 422:
            if(shouldReturn){
                return "Unproceseable entity:The given data was invalid or some data was miss"
            }
            dispatch({
                type: T.SET_APP_ERROR_MESSAGE,
                payload:"Unproceseable entity:The given data was invalid or some data was miss",
            })
        break
        case 409:
            const {codenumber,codemessage} = data
            let message = "Unknow error"
            if(codenumber!==undefined){
                message = errorlist[codenumber]!==undefined?errorlist[codenumber]:"Unknow error"
            }
            console.log(codemessage,codenumber)

            if(shouldReturn){
                return "Conflict:"+message
            } 


            dispatch({
                type: T.SET_APP_ERROR_MESSAGE,
                payload: "Conflict:"+message,
            })
        break        
        default:
            if(shouldReturn){
                return "Ups! something went wrong. Please try again later"
            } 
            dispatch({
                type: T.SET_APP_ERROR_MESSAGE,
                payload: "Ups! something went wrong. Please try again later",
            })
        break
    }
}