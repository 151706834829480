import React from 'react';

const LocalMessage = (props) => {
    const {
        message="",
        type,
        alignClass="text-center"
    } = props

    const renderMessage = (message,type)=>{
        let classMessage = "text-primary"
        switch (type) {
            case 'error':
                classMessage="text-danger"
                break;
            case 'success':
                classMessage="text-success"
                break;
            case 'warning':
                classMessage="text-warning" 
            default:
                break;
        }

        return <span className={`${alignClass} ${classMessage}`}>
            {message}
        </span>

    }

    return (
        renderMessage(message,type)
    );
}

export default LocalMessage;
