import React from 'react';

const DriverLevel = (props) => {
    const{
        level
    }=props

    const renderLevel = (level) => {
        switch (level) {
            default:
                return <div>
                    Gold
                </div>
                
        }
    }

    return (
        <div className='my-1 text-center'>
            {renderLevel(level)}
        </div>
    );
}

export default DriverLevel;
