import React from 'react';

const ImageContainer = (props) => {
    const {
        image,
        context,
        onClick
    }= props

    return (
        <div className='w-100 border rounded border-white overflow-hidden cursor-pointer border-accent position-relative bg-gray card-plate' onClick={()=>{onClick(image)}}>
            <div className="transparent-layer transparent-layer-black">            
            </div>
            <div className="position-absolute absolute-center">
                <div className="icon-container">
                    <h3 className="text-center">
                        <i className="fa fa-picture-o text-white"></i>
                    </h3>
                </div>
            </div>
            {
            image?<div className='center-image-container'>
                <img src={image} alt={`${context} image`} className="w-100" />
            </div>
            :<div className="text-center mt-4">
                <h6 className='text-white'>There is no {context}</h6>
            </div>
            }
        </div>
    );
}

export default ImageContainer;
