import React,{useState,useEffect,useRef} from 'react';
import { useDispatch,useSelector } from 'react-redux';


import * as appActions from '../../actions/app.actions'
import * as ridesActions from '../../actions/rides.actions'

import BasicList from '../../components/BasicList';
import PaginationFooter from '../../components/PaginationFooter';

import FormatedDate from '../../components/FormatedDate';

import { useNavigate } from 'react-router-dom';

const LandingContact = (props) => {    
    const {

    }=props

    const navigate = useNavigate()

    const dispatch = useDispatch()

    const contactTypeStrings = {             
        "drivers":"Driver wanting to join",
        "passenger-qs":"Passenger questions",
        "bugs":"Report a bug",
        "questions-comments":"Comments & questions",
        "invest":"Investment opportunities",
        "press":"Press & Interviews",
    }

    const searchParamsInitialState = {
        "user":"",
        "fromDate":"",
        "toDate":"",
        "active":false,
        "payment":"*"
    }
    const paginationInitialState = {
        "currentPage":1,
        "totalPages":1
    }

    
    const [searchParams, setSearchParams] = useState(searchParamsInitialState);
    const [pagination, setPagination] = useState(paginationInitialState);
    
    const [rides, setRides] = useState({
        "list":[],
        "loading":false
    });

    const searchParamsRef = useRef(searchParamsInitialState)

    const getEmails = (page,onFinish) => {
      

        setRides({
            ...rides,
            'loading':true
        })

        dispatch(ridesActions.getContactEmails(            
            page
        )).then(
            (response)=>{
                console.log('response on index',response)
                console.log(response.emails.data)
                setRides({
                    'list':response.emails.data,
                    'loading':false
                })
                //TODO total pages
                setPagination({
                    ...pagination,
                    "currentPage":response.emails.current_page,
                    "totalPages":response.emails.last_page
                })
                onFinish(true)
            },
            (error)=>{
                //TODO handle Error
                setPagination({
                    ...pagination,
                    "currentPage":1,
                    "totalPages":1
                })
                setRides({
                    ...rides,
                    'list':[],
                    'loading':false
                })
                onFinish(false)
            }
        )
    }    

    useEffect(() => {
        dispatch(
            appActions.setNav("EMAILS")
        )
        getEmails(1,(p)=>{console.log('First call')})
    }, []);

    useEffect(() => {
        console.log('update search params',searchParams)
        searchParamsRef.current = searchParams
    }, [searchParams]);
    


    return (        
        <div className='row col-12 justify-content-center'> 
            <div className="col-12 my-2">
                <h4>
                    Contact mails
                </h4>    
            </div>     
            <div className="row justify-content-between">
                <div className="col-auto">
                    <button className="btn btn-primary btn-sm my-3" onClick={
                        ()=>{navigate(-1)}
                    }>
                        back
                    </button>
                </div>               
            </div>                  
            <BasicList 
                list={rides.list}
                loading={rides.loading}
                noDataText={"there are no contact mails"}
                loadingText={"loading contact mails..."}
                renderFunction={(model)=>{                    
                    return <div key={model.id} className='row my-1 justify-content-center'>
                        <div className="col-10">
                            <div className="border border-dark p-1">
                                <h5 className='text-start'>
                                    <strong>
                                        {contactTypeStrings[model.contact_type]}
                                    </strong>
                                </h5>  
                                <h6 className='text-start'>
                                    {model.name}
                                </h6>
                                <h6 className="text-start">
                                    <i className="fa fa-envelope-o me-1"></i>{model.email} <i className="fa fa-phone mx-1"></i> {model.phone}
                                </h6>                                                              
                                <p className='text-start text-muted'>
                                    {model.message}
                                </p>
                                <p className="text-end">
                                    <FormatedDate type="basic"> 
                                        {model.updated_at}
                                    </FormatedDate>                                
                                </p>
                            </div>
                        </div>                        
                    </div>                     
                }}
            />
            
            <PaginationFooter
                currentPage={pagination.currentPage}
                totalPages={pagination.totalPages}
                onNext={(page)=>{
                    setPagination({
                        ...pagination,
                        "currentPage":page
                    })
                    getEmails(                        
                        page,
                        ()=>{
                            console.log('pageNext',page)
                        }
                    )

                }}
                onPrev={(page)=>{
                    setPagination({
                        ...pagination,
                        "currentPage":page
                    })
                    getEmails(                        
                        page,
                        ()=>{
                            console.log('pagePrev',page)
                        }
                    )
                }}
            />
        </div>
    );
}

export default LandingContact;
