import React from 'react';
import UserImage from './UserImage';
import FormatedDate from './FormatedDate';

const MessageItem = ({
    driver=null,
    passenger=null,
    model
}) => {
    return (
        <div className={`row ${passenger.id==model.id_user_sending?"justofy-content-start":"justify-content-end"}`}>
            {
                passenger.id==model.id_user_sending?
                <div className="col-8">
                    <div className='card-message'>
                        <div className='row justify-content-start'>
                            <div className="col-auto">
                                <UserImage  
                                    user={passenger}
                                    userType="passenger"
                                    size="sm"
                                />
                                <p className="text-center mt-1">
                                    <i className="fa fa-user"></i>
                                </p>
                            </div>
                            <div className="col">
                                <p className="p-3 text-end text-muted">                                   
                                    {model.message}
                                </p>
                                <p className='text-end text-muted'>
                                    <small>
                                        <FormatedDate
                                            type="basic"
                                        >
                                            {model.send_time}
                                        </FormatedDate>
                                    </small>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                :
                <div className="col-8">
                    <div className='card-message'>
                        <div className='row justify-content-end'>                           
                            <div className="col">
                                <p className="p-3 text-end text-muted">
                                    {model.message}
                                </p>
                                <p className='text-end text-muted'>
                                    <small>
                                        <FormatedDate
                                            type="basic"
                                        >
                                            {model.send_time}
                                        </FormatedDate>
                                    </small>
                                </p>
                            </div>
                            <div className="col-auto">
                                <UserImage  
                                    user={driver}
                                    userType="driver"
                                    size="sm"
                                />
                                <p className="text-center mt-1">
                                    <i className="fa fa-car"></i>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

            }
        </div>
    );
}

export default MessageItem;
