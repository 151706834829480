import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate,useParams } from 'react-router-dom';

import * as authActions from '../../actions/auth.actions'

import RecoverPasswordForm from './components/RecoverPasswordForm';

const RecoverPassword = () => {
    
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {token} = useParams()
    
    const handleRecoveryPassword = (params,onSuccess,onError)=>{
        const {
            password,
            password_confirmation,
            email
        }= params
        console.log(params)
        dispatch(
            authActions.passwordreset(token,email,password,password_confirmation)
        ).then(
            (response)=>{
                onSuccess(response)
            },
            (error)=>{
                onError(error)
            }
        )
    }
    
    return (
        <div className=''>
            <div className="container my-5">
                <div className="row justify-content-center">
                    <div className="col-md-4 card py-2">
                        <div className="bg-white w-100">
                            <h3 className="text-center mb-5">
                                Buscaride CRM
                            </h3>
                            <RecoverPasswordForm 
                                onSubmit={handleRecoveryPassword}
                                onGoLogin={()=>{ navigate('/') }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default RecoverPassword;
