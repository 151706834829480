import React,{useState,useEffect,useRef} from 'react';
import "./index.css"

import DriverGalleryDots from './DriverGalleryDots';

import constants from '../../../constants';
 

const DriverGallery = (props) => {
    const {
        elements=[],
        step,
        onBack,
        onNext,      
        onDotClick
    } = props

    const handleBack = ()=>{
        console.log('back',elements[step])
        onBack()
    }
    const handleNext = ()=>{     
        console.log('next',elements[step])       
        onNext()        
    }

    const handleDotClick = (index)=>{
        onDotClick(index)
    }

    return (        
        <div className="row justify-content-center">      
            {
                elements.length>0?
                <div className="col-12 position-relative gallery-frame">  
                    <div className="frame-absolute h-100 w-100 text-center">
                        <div className="row justify-content-center">
                            <div className="col-auto text-center">
                                <DriverGalleryDots
                                    step={step}
                                    elements={elements}
                                    onItemClick={handleDotClick}
                                />         
                            </div>
                        </div>
                        <div className="row align-items-center justify-content-between h-100">
                            <div className="col-auto">
                                <div className="btn-sm btn btn-primary rounded-circle" onClick={handleBack}>
                                    <h3><i className="text-white fa fa-chevron-left p-1"></i></h3>
                                </div>                
                            
                            </div>
                            <div className="col-auto">
                                <div className="btn-sm btn btn-primary rounded-circle" onClick={handleNext}>
                                    <h3><i className="text-white fa fa-chevron-right p-1"></i></h3>
                                </div>
                            </div>
                        </div>       
                    </div>
                    <div className="gallery-frame-image">
                        <img className='w-100' src={`${constants.resources.BASE_DRIVER_SLIDE_CDN_URL}${elements[step].id_driver}-${elements[step].id}.jpg`} alt={`image ${step}`}/>                 
                    </div>                                
                </div>:
                <div className="col-12">
                    <div className="gallery-no-data-container w-100">
                        <h6 className="text-white text-center pt-5">
                            The driver doesn't have images
                        </h6>
                    </div>
                </div>
                
            }
            

        </div>        
    );
}

export default DriverGallery;
