import React from 'react';
import './index.css'

const DriverGalleryDots = (props) => {
    const {
        step,
        elements,
        onItemClick
    }= props

    const handleItemClick = (index)=>{     
        console.log('click',index)   
        onItemClick(index)
    }

    return (                    
        <div className="dots">               
            {                    
                elements.map((e,index)=>{
                    return <div key={index} onClick={()=>{handleItemClick(index)}} className={`dots__dot ${step==index?"dots__dot--active":""}`}></div>
                })
            }
        </div>        
    );
}

export default DriverGalleryDots;
