const constants = {
    //url:"https://crm.askzhao.com/azadmin/public/api/v1/admin",
    url:"https://api.crm.buscaride.com/br-crm/public/api/v1/admin",
    //url:"https://fkrdp66dlf.execute-api.us-east-1.amazonaws.com/api/v1/admin",
    //url:"http://192.168.0.41/buscaride_crm_api/public/api/v1/admin",
    //url:"http://localhost/buscaride_crm_api/public/api/v1/admin",
    GM_API_KEY:"AIzaSyCmLVYw6TSuvlso6ILBgLfcmWxH5u5a0JY",
    name:"Buscaride CRM",
    resources:{
        BASE_PASSENGERS_CDN_URL:"https://buscaride-small.s3.amazonaws.com/im/passengers/",
        BASE_PASSENGERS_BIG_CDN_URL:"https://buscaride.s3.amazonaws.com/im/passengers/",
        BASE_DRIVERS_CDN_URL:"https://buscaride-small.s3.amazonaws.com/im/drivers/",
        BASE_DRIVERS_CDN_BIG_URL:"https://buscaride.s3.amazonaws.com/im/drivers/",
        BASE_DRIVERS_VIDEO_SMALL_CDN_URL: "https://buscaride-small.s3.amazonaws.com/vid/drivers/",
        BASE_DRIVERS_VIDEO_CDN_URL:"https://buscaride.s3.amazonaws.com/vid/drivers/",        
        BASE_DRIVER_SLIDE_CDN_URL:"https://buscaride.s3.amazonaws.com/im/driverslides/",
        BASE_DRIVERS_CDN_URL_ID:"https://buscaride-small.s3.amazonaws.com/im/drivers/id/",
        BASE_DRIVERS_CDN_URL_LICENSE:"https://buscaride-small.s3.amazonaws.com/im/drivers/license/",
        // BASE_PASSENGERS_CDN_URL:"https://buscaride-dev-small.s3.amazonaws.com/im/passengers/",
        // BASE_PASSENGERS_BIG_CDN_URL:"https://buscaride-dev.s3.amazonaws.com/im/passengers/",
        // BASE_DRIVERS_CDN_URL:"https://buscaride-dev-small.s3.amazonaws.com/im/drivers/",
        // BASE_DRIVERS_CDN_BIG_URL:"https://buscaride-dev.s3.amazonaws.com/im/drivers/",
        // BASE_DRIVERS_VIDEO_SMALL_CDN_URL: "https://buscaride-dev-small.s3.amazonaws.com/vid/drivers/",
        // BASE_DRIVERS_VIDEO_CDN_URL:"https://buscaride-dev.s3.amazonaws.com/vid/drivers/",        
        // BASE_DRIVER_SLIDE_CDN_URL:"https://buscaride-dev.s3.amazonaws.com/im/driverslides/",
        // BASE_DRIVERS_CDN_URL_ID:"https://buscaride-dev-small.s3.amazonaws.com/im/drivers/id/",
        // BASE_DRIVERS_CDN_URL_LICENSE:"https://buscaride-dev-small.s3.amazonaws.com/im/drivers/license/"        
    }
}

export default constants