import React,{useState,useEffect} from 'react';
import { useDispatch,useSelector } from 'react-redux';

import * as appActions from '../../actions/app.actions'
import * as ridesActions from '../../actions/rides.actions'

import DriversListItem from './components/DriversListItem';
import DriversSearchNav from './components/DriversSearchNav';

import BasicList from '../../components/BasicList';
import PaginationFooter from '../../components/PaginationFooter';

import CRMModal from '../../components/CRMModal';

import UserReportsNames from '../../components/UserReportsNames';
import FormatedDate from '../../components/FormatedDate';

import { useNavigate } from 'react-router-dom';

const Drivers = (props) => {    
    const {

    }=props

    const navigate = useNavigate()

    const dispatch = useDispatch()

    const searchParamsInitialState = {
        "user":"",
        "fromDate":"",
        "toDate":"",
        "enabled":1,
        "onlyReported":false        
    }
    const paginationInitialState = {
        "currentPage":1,
        "totalPages":1
    }

    
    const [searchParams, setSearchParams] = useState(searchParamsInitialState);
    const [pagination, setPagination] = useState(paginationInitialState);
    const [showReports, setShowReports] = useState([]);
    
    const [list, setList] = useState({
        "list":[],
        "loading":false
    });

    const getApi = (params,page,onFinish=(param)=>{console.log(param)}) => {
        const {
            user,
            fromDate,
            toDate,
            enabled,
            onlyReported
        } = params
        

        setList({
            ...list,
            'loading':true
        })

        dispatch(ridesActions.getDrivers(
            user,
            fromDate,
            toDate,
            enabled,
            onlyReported?1:0,
            page
        )).then(
            (response)=>{
                console.log('get drivers',response)
                setList({
                    'list':response.data,
                    'loading':false
                })
                //TODO total pages
                setPagination({
                    ...pagination,
                    "currentPage":response.current_page,
                    "totalPages":response.last_page
                })
                onFinish(true)
            },
            (error)=>{
                console.log('error?')
                //TODO handle Error
                setPagination({
                    ...pagination,
                    "currentPage":1,
                    "totalPages":1
                })
                setList({
                    ...list,
                    'loading':false
                })
                onFinish(false)
            }
        )
    }
    
    const handleShowReports = (reports)=>{
        setShowReports(reports)        
    }

    const handleUserClick = (model)=>{
        console.log("Open driver",model)
        navigate('/driver/'+model.id)
    }

    useEffect(() => {
        dispatch(
            appActions.setNav("DRIVERS")
        )
        getApi(searchParams,1,(p)=>{console.log('First call')})
    }, []);


    return (
        <div className='row col-12 justify-content-center'>
            {
                showReports.length>0&&
                <CRMModal
                    title="Driver reports"
                    onClose={()=>{
                        setShowReports([])
                    }}
                >
                    <div>
                        <ol>
                            {showReports.map((item)=>{
                                return <li>                                            
                                    <p className='text-start'><small><b><UserReportsNames type='driver' id={item.report_type}/></b>: {item.comment}</small></p>
                                    <p className="text-end">
                                        <small>
                                            <i><FormatedDate type="basic">{item.report_date}</FormatedDate></i>
                                        </small>
                                    </p>
                                </li>
                            })} 
                        </ol>
                    </div>
                </CRMModal>
            }

            <DriversSearchNav 
                searchParams={searchParams}
                onChange={(params,onFinish)=>{
                    console.log('search params',params)
                    setSearchParams({
                        ...searchParams,
                        ...params
                    })
                    getApi(
                        params,
                        1,
                        onFinish
                    )                    
                }}                
            />            
            <BasicList 
                list={list.list}
                loading={list.loading}
                noDataText={"No drivers"}
                loadingText={"loading drivers..."}
                renderFunction={(model)=>{
                    return <DriversListItem 
                        key={model.id}
                        model={model}
                        onUserClick={handleUserClick}  
                        onShowReports={handleShowReports}                     
                    />
                }}
            />
            <PaginationFooter
                currentPage={pagination.currentPage}
                totalPages={pagination.totalPages}
                onNext={(page)=>{
                    setPagination({
                        ...pagination,
                        "currentPage":page
                    })
                    getApi(
                        searchParams,
                        page,
                        ()=>{
                            console.log('pageNext',page)
                        }
                    )

                }}
                onPrev={(page)=>{
                    setPagination({
                        ...pagination,
                        "currentPage":page
                    })
                    getApi(
                        searchParams,
                        page,
                        ()=>{
                            console.log('pagePrev',page)
                        }
                    )
                }}
            />
        </div>
    );
}

export default Drivers;
