import {
    LOGIN_SUCCESS,    
    LOGOUT,
  } from "../actions/types";
  
  const auth = JSON.parse(localStorage.getItem("auth"));
  const initialState = auth
    ? { isLoggedIn: true, session:auth }
    : { isLoggedIn: false, session: null };
  
  export default function (state = initialState, action) {
    const { type, payload } = action;
  
    switch (type) {     
      case LOGIN_SUCCESS:
        console.log(payload.session)
        localStorage.setItem('auth',JSON.stringify({
          ...state,
          isLoggedIn: true,
          session: payload.session,
        }))
        return {
          ...state,
          isLoggedIn: true,
          session: payload.session,
        };
      case LOGOUT:
        localStorage.removeItem("auth")
        return {
          ...state,
          isLoggedIn: false,
          session: null,
        };
      default:
        return state;
    }
}