import C from "../constants"
import axios from "axios";
import authHeader from './auth-header';

const API_URL = C.url + "";

class AuthService {

    login(email, password) {
    return axios
        .post(API_URL + "/login", { email, password })
        .then((response) => {
        console.log("response...",response);
        if (response.data.access_token) {
            localStorage.setItem("auth", JSON.stringify({username:email,"token":response.data.access_token}));
        }
        return {username:email,"token":response.data.access_token};
        });
    }

    logout() {
        return axios
        .get(API_URL + "/logout",{ headers:authHeader()})
        .then((response) =>{
            localStorage.removeItem("auth");
        })
    }

    passwordresetrequest(email) {
    return axios
        .post(API_URL + "/password-reset-request", { email })
        .then((response) => {
        console.log(response);
        return {}
        });
    }

    passwordreset(token,email,password,password_confirmation) {
        return axios
        .post(API_URL + "/password-reset",{token,email,password,password_confirmation})
        .then((response) =>{
            console.log(response.data);
            return {}          
        })
    }

}

export default new AuthService();