import React,{useRef,useEffect} from 'react';
import { GoogleMap, useJsApiLoader, Marker,useGoogleMap} from '@react-google-maps/api'
import c from '../constants'

import mapIconStart from '../../src/assets/map_start.png'
import mapIconEnd from '../../src/assets/map_end.png'

const Map = ({A,B}) => {
    const map = useGoogleMap()
    useEffect(() => {
        if (map) {
            let bounds = new window.google.maps.LatLngBounds();
            bounds.extend({
               ...A
            });
            bounds.extend({
               ...B
            });      
            map.fitBounds(bounds)
        }
    }, [map])
}

const CRMGoogleMap = ({A,B}) => {
    const mapRef = useRef(null)
    

    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: c.GM_API_KEY,
    });

    const center = {
        lat:A.lat + (A.lat - B.lat) *.05,
        lng:A.lng + (A.lng - B.lng) *.05
    }

    const onLoad = (marker) => {
        console.log("marker: ", marker);
    };

    const options = {
        mapTypeControl: false,
        streetViewControl: false,
        fullscreenControl: false,
    };
    

   
    // useEffect(() => {
    //     if(isLoaded){
    //         console.log(
    //             mapRef.current
    //         )
    //         let bounds = new window.google.maps.LatLngBounds();
    //         bounds.extend({
    //            ...A
    //         });
    //         bounds.extend({
    //            ...B
    //         });            
    //     }
    // }, [isLoaded]);

    if (!isLoaded) return <div>Loading...</div>;

    return (
        <GoogleMap 
            ref={mapRef} 
            zoom={15} 
            options={options} 
            center={center} 
            mapContainerClassName="map-container"            
            >
            <Marker icon={mapIconStart} position={{...A}}/>
            <Marker icon={mapIconEnd} position={{...B}} />
            <Map A={A} B={B} ></Map>

        </GoogleMap>
    );
}

export default CRMGoogleMap;
