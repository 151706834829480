import React from 'react';

const SocialMediaContainer = (props) => {
    const {
        twitter,
        facebook,
        whatsapp
    }= props

    return (
        <div className='row my-2 justify-content-center'>
            <div className="col-12 my-1">
                <h5 className="text-center">
                    Driver's Social Media
                </h5>
            </div>
            
            {
                twitter&&
                <div className="col py-3">
                    <h4 className="text-center text-twitter">
                        <a
                            className=''
                            href={twitter}
                            target='_blank'
                            rel="noopener"
                            aria-label=''
                        >
                            <i className='fa fa-twitter' /> {twitter}
                        </a>
                    </h4>
                </div>
            }
            {
                facebook&&
                <div className="col py-3">
                    <h4 className="text-center text-facebook">
                        <a
                            className=''
                            href={facebook}
                            target='_blank'
                            rel="noopener"
                            aria-label=''
                        >
                            <i className='fa fa-facebook' /> {facebook}
                        </a>
                    </h4>
                </div>
            }
            {
                whatsapp&&
                <div className="col py-3">
                    <h4 className="text-center text-whatsapp">
                        <a
                            className=''
                            href={whatsapp}
                            target='_blank'
                            rel="noopener"
                            aria-label=''
                        >
                            <i className='fa fa-whatsapp' /> {whatsapp}
                        </a>
                    </h4>
                </div>
            }
            {
                twitter==null||facebook==null||whatsapp==null?
                <div className="col-12 py-3">
                    <h6 className="text-center text-muted">
                        There is no social media
                    </h6>
                </div>
                : <div>
                    
                </div> 
            }
           
        </div>
    );
}

export default SocialMediaContainer;
