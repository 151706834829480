import React from 'react';

const CRMModalImage = (props) => {
    const {               
        image=null,
        onClose      
    } = props

    return (
        <div className="crm-modal-container" onClick={()=>{onClose()}}>
            <div className='crm-modal crm-modal--image' style={{backgroundImage:`url(${image})`}}>
                <div className="crm-modal__close">
                    <h4 className='text-center text-white cursor-pointer' onClick={()=>{onClose()}}>
                        <i className="fa fa-times"></i>
                    </h4>
                </div>
            </div>
        </div>
    );
}

export default CRMModalImage;
