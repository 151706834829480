import React from 'react';

const UserReportsNames = ({id,type="passenger"}) => {

    const passengerReportsNames = [
        "The passenger was rude",
        "The passenger damaged my car",
        "The passenger did not arrive on time",
        "The passenger did not pay the",
        "I had a security problem",
        "I have a different problem",
    ]
    const driverReportsNames = [
        "The driver was not professional",
        "The car was not what I expected",
        "Did not arrive on time",
        "I lost an item",
        "I had a security problem",
        "I have a different problem",
    ]

    return (
        <span>
            {console.log(type)}
            {
                type=="passenger"?
                passengerReportsNames[id]
                :
                driverReportsNames[id]
            }
        </span>
    );
}

export default UserReportsNames;
