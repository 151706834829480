import React,{useState,useEffect,useRef} from 'react';
import { useDispatch,useSelector } from 'react-redux';


import * as appActions from '../../actions/app.actions'
import * as ridesActions from '../../actions/rides.actions'

import RidesSearchNav from './components/RidesSearchNav';
import RideListItem from './components/RideListItem';
import BasicList from '../../components/BasicList';
import PaginationFooter from '../../components/PaginationFooter';
import CRMModal from '../../components/CRMModal';
import CRMMap from '../../components/CRMMap';
import FormatedDate from '../../components/FormatedDate';
import UserReportsNames from '../../components/UserReportsNames';
import MessageItem from '../../components/MessageItem';
import CRMGoogleMap from '../../components/CRMGoogleMap';

import { useNavigate } from 'react-router-dom';

const Rides = (props) => {    
    const {

    }=props

    const navigate = useNavigate()

    const dispatch = useDispatch()

    const searchParamsInitialState = {
        "user":"",
        "fromDate":"",
        "toDate":"",
        "active":false,
        "payment":"*"
    }
    const paginationInitialState = {
        "currentPage":1,
        "totalPages":1
    }

    
    const [searchParams, setSearchParams] = useState(searchParamsInitialState);
    const [pagination, setPagination] = useState(paginationInitialState);
    const [paginationConversations, setPaginationConversations] = useState(paginationInitialState);

    const [showMap, setshowMap] = useState(false);
    const [driverReports, setdriverReports] = useState([]);
    const [passengerReports, setpassengerReports] = useState([]);
    const [map, setMap] = useState(null);
    const [showChat, setshowChat] = useState(false);
    const [showReports, setshowReports] = useState(false);
    
    const [rides, setRides] = useState({
        "list":[],
        "loading":false
    });
    const [messages, setMessages] = useState({
        "list":[],
        "loading":false
    });

    const searchParamsRef = useRef(searchParamsInitialState)

    const getConversations = (id,page,onFinish,extras)=>{
        setMessages({
            ...messages,
            'loading':true
        })

        dispatch(ridesActions.getConversations(
            id,page,onFinish
        )).then(
            (response)=>{
                setMessages({
                    'list':response.data,
                    'loading':false
                })
                //TODO total pages
                setPaginationConversations({
                    ...pagination,
                    "ride":id,
                    "currentPage":response.current_page,
                    "totalPages":response.last_page,
                    ...extras
                })
                onFinish(true)
            },
            (error)=>{
                //TODO handle Error
                setPaginationConversations({
                    ...paginationConversations,
                    "ride":0,
                    "currentPage":1,
                    "totalPages":1
                })
                setMessages({
                    ...messages,
                    'list':[],
                    'loading':false
                })
                onFinish(false)
            }
        )
    }

    const getRides = (params,page,onFinish) => {
        const {
            user,
            fromDate,
            toDate,
            active,
            payment
        } = params
        

        setRides({
            ...rides,
            'loading':true
        })

        dispatch(ridesActions.getRides(
            user,
            fromDate,
            toDate,
            active?1:0,
            payment,
            page
        )).then(
            (response)=>{
                setRides({
                    'list':response.data,
                    'loading':false
                })
                //TODO total pages
                setPagination({
                    ...pagination,
                    "currentPage":response.current_page,
                    "totalPages":response.last_page
                })
                onFinish(true)
            },
            (error)=>{
                //TODO handle Error
                setPagination({
                    ...pagination,
                    "currentPage":1,
                    "totalPages":1
                })
                setRides({
                    ...rides,
                    'list':[],
                    'loading':false
                })
                onFinish(false)
            }
        )
    }

    const handleSeeMap = (model)=>{
        console.log("See map",model)
        setMap({
            A:{
                lat:parseFloat(model.start_lat),
                lng:parseFloat(model.start_lon)
            },
            B:{
                lat:parseFloat(model.end_lat),
                lng:parseFloat(model.end_lon)
            }
        })        
    }
    const handleSeeChat = (model)=>{
        console.log("See chat",model)
        getConversations(model.id,1,()=>{
            
        },{
            'passenger':model.passenger,
            'driver':model.driver,
        })
        setshowChat(true)
    }
    const handleSeeReports = (model)=>{
        console.log("See reports",model)
        setdriverReports(model.driver_reports?model.driver_reports:[])
        setpassengerReports(model.passenger_reports?model.passenger_reports:[])
        setshowReports(true)
    }
    const handlePassengerClick = (model)=>{
        console.log("See passenger profile",model)
        navigate('/passenger/'+model.id)
    }
    const handleDriverClick = (model)=>{
        console.log("See driver profile",model)
        navigate('/driver/'+model.id)

    }

    useEffect(() => {
        dispatch(
            appActions.setNav("RIDES")
        )
        getRides(searchParams,1,(p)=>{console.log('First call')})
    }, []);

    useEffect(() => {
        console.log('update search params',searchParams)
        searchParamsRef.current = searchParams
    }, [searchParams]);
    


    return (
        <div className='row col-12 justify-content-center'>
            {map && 
                <CRMModal
                    title="Ride map"                    
                    footer=""
                    onClose={ ()=>{setMap(null)} }
                >
                    <div className="map-container">
                        <CRMGoogleMap 
                            A={map.A} 
                            B={map.B} 
                        />
                    </div>
                </CRMModal>
            }
            {showChat && 
                <CRMModal
                    title="Ride chat"                    
                    footer=""
                    onClose={ ()=>{setshowChat(false);setMessages({
                        ...rides,
                        'list':[],
                        'loading':false
                    })} }
                >                    
                    <div>
                        <BasicList 
                            list={messages.list}
                            loading={messages.loading}
                            noDataText={"There are no conversations in this ride"}
                            loadingText={"loading messages..."}
                            renderFunction={(model)=>{                    
                                return <MessageItem 
                                    key={model.id}
                                    model={model}
                                    driver={paginationConversations.driver}
                                    passenger={paginationConversations.passenger}
                                ></MessageItem>                                  
                            }}
                        />                
                        <PaginationFooter
                            currentPage={paginationConversations.currentPage}
                            totalPages={paginationConversations.totalPages}
                            onNext={(page)=>{
                                setPaginationConversations({
                                    ...paginationConversations,
                                    "currentPage":page
                                })
                                getConversations(
                                    paginationConversations.ride,
                                    page,
                                    ()=>{
                                        console.log('pageNext',page)
                                    },
                                    {
                                        passenger:paginationConversations.passenger,
                                        driver:paginationConversations.driver,
                                    }
                                )

                            }}
                            onPrev={(page)=>{
                                setPaginationConversations({
                                    ...paginationConversations,
                                    "currentPage":page                                    
                                })
                                getConversations(
                                    pagination.ride,
                                    page,
                                    ()=>{
                                        console.log('pagePrev',page)
                                    },
                                    {
                                        passenger:paginationConversations.passenger,
                                        driver:paginationConversations.driver,
                                    }
                                )
                            }}
                        />    
                </div>
                </CRMModal>
            }
            {showReports && 
                <CRMModal
                    title="Reports"                    
                    footer=""
                    onClose={ ()=>{setshowReports(false);setpassengerReports([]);setdriverReports([]);} }
                >
                    <div className='p-2'>
                        {driverReports.length>0?
                            <div className=''>
                                <h5>Reports to driver</h5>
                                <ol>
                                    {driverReports.map((item)=>{
                                        return <li>                                            
                                            <p className='text-start'><small><b><UserReportsNames  id={item.report_type}/></b>: {item.comment}</small></p>
                                            <p className="text-end">
                                                <small>
                                                    <i><FormatedDate type="basic">{item.report_date}</FormatedDate></i>
                                                </small>
                                            </p>
                                        </li>
                                    })} 
                                </ol>
                            </div>
                        :
                            <div>
                                <p className="text-start text-muted">
                                    There are no reports to driver
                                </p>
                            </div>
                        }
                        {passengerReports.length>0?
                            <div className=''>
                                <h5>Reports to passenger</h5>
                                <ol>
                                    {passengerReports.map((item)=>{
                                        return <li>                                            
                                            <p className='text-start'><small><b><UserReportsNames type='driver' id={item.report_type} /></b>: {item.comment}</small></p>
                                            <p className="text-end">
                                                <small>
                                                    <i><FormatedDate type="basic">{item.report_date}</FormatedDate></i>
                                                </small>
                                            </p>
                                        </li>
                                    })} 
                                </ol>
                            </div>
                        :
                            <div>
                                <p className="text-start text-muted">
                                    There are no reports to passenger
                                </p>
                            </div>
                        }
                    </div>  
                </CRMModal>
            }
            <RidesSearchNav                 
                onChange={(params,onFinish)=>{

                    setSearchParams({
                        ...searchParams,
                        ...params
                    })
                    getRides(
                        params,
                        1,
                        onFinish
                    )                    
                }}   
                searchParams={searchParams}             
            />
            <BasicList 
                list={rides.list}
                loading={rides.loading}
                noDataText={"there is no rides"}
                loadingText={"loading rides..."}
                renderFunction={(model)=>{                    
                    return <RideListItem 
                        key={model.id}
                        model={model}
                        onSeeMap={handleSeeMap}
                        onSeeChat={handleSeeChat}
                        onSeeReports={handleSeeReports}
                        onPassengerClick={handlePassengerClick}
                        onDriverClick={handleDriverClick}
                    />
                }}
            />
            <PaginationFooter
                currentPage={pagination.currentPage}
                totalPages={pagination.totalPages}
                onNext={(page)=>{
                    setPagination({
                        ...pagination,
                        "currentPage":page
                    })
                    getRides(
                        searchParams,
                        page,
                        ()=>{
                            console.log('pageNext',page)
                        }
                    )

                }}
                onPrev={(page)=>{
                    setPagination({
                        ...pagination,
                        "currentPage":page
                    })
                    getRides(
                        searchParams,
                        page,
                        ()=>{
                            console.log('pagePrev',page)
                        }
                    )
                }}
            />
        </div>
    );
}

export default Rides;
