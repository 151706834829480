import Axios from "axios";
import {
    setupCache,
    buildMemoryStorage,
    defaultKeyGenerator,
    defaultHeaderInterpreter
} from 'axios-cache-interceptor';
const axios = setupCache(
    // axios instance
    Axios.create(),
  
    // All options with their default values
    {
      // The storage to save the cache data. There are more available by default.
      //
      // https://axios-cache-interceptor.js.org/#/pages/storages
      storage: buildMemoryStorage(),
  
      // The mechanism to generate a unique key for each request.
      //
      // https://axios-cache-interceptor.js.org/#/pages/request-id
      generateKey: defaultKeyGenerator,
  
      // The mechanism to interpret headers (when cache.interpretHeader is true).
      //
      // https://axios-cache-interceptor.js.org/#/pages/global-configuration?id=headerinterpreter
      headerInterpreter: defaultHeaderInterpreter,
  
      // The function that will receive debug information.
      // NOTE: For this to work, you need to enable development mode.
      //
      // https://axios-cache-interceptor.js.org/#/pages/development-mode
      // https://axios-cache-interceptor.js.org/#/pages/global-configuration?id=debug
      debug: undefined
    }
  );

class AppServices{

    getImage(src,abortController=null){
        let ac;
        if(abortController == null){
            ac = new AbortController()
        }else{
            ac = abortController
        }        
        return axios
        .get(src,{
            signal:ac.signal,
        })
        .then((response) =>{               
            return response;
        })
    }

}

export default new AppServices();