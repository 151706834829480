import React,{useEffect,useState,useRef} from 'react';

import ProfileHeader from './components/ProfileHeader';
import ReviewsList from '../../components/ReviewsList';

import CRMModal from '../../components/CRMModal';
import UserReportsNames from '../../components/UserReportsNames';
import FormatedDate from '../../components/FormatedDate';

import * as rideActions from '../../actions/rides.actions'

import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import constants from '../../constants';

const ProfilePassenger = (props) => {
    const {id} = useParams()

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const profileInitState = {
        id:null,
        user:{
            'id':"#",
            'image_sufix':0,
            'level':0,
            'name':"",
            'last_name':"",
            'rides':"#",
            'rating':0.0
        },
        description:null,
        recommend:null,
        /*id,
        email,
        name,
        last_name,
        subtitle,
        description,
        image_sufix,
        lower_unaccent_name,
        lower_unaccent_subtitle,
        rides,
        cancelled,
        active_reports,
        total_reports,
        level,
        rating,
        rates,
        gender,
        hasvideo,
        video_sufix,
        status_description,
        status_car,
        status_companies,                
        status_licence,
        ready_to_ride_status,
        car_color,
        facebook,
        twitter,
        whatsapp,
        recommends*/
    }

    const [profile, setProfile] = useState(profileInitState);
    const [galleryIndex, setGalleryIndex] = useState(0);
    const [showReports, setShowReports] = useState([]);

    const apiGet = (userId)=>{  
        dispatch(
            rideActions.getPassenger(userId)
        ).then(
            (response)=>{
                console.log('the response',response.passenger)
                
                setProfile({
                    ...profile,
                    ...response.passenger,
                    'user':{
                        'id':response.passenger.id,
                        'image_sufix':response.passenger.image_sufix,
                        'level':response.passenger.level,
                        'name':response.passenger.name,
                        'last_name':response.passenger.last_name,
                        'rides':response.passenger.rides,
                        'rating':response.passenger.rating
                    }
                })
            },
            (error)=>{
                console.log('the error',error)
            }
        )
    }

    const handleItemClick = (model)=>{

    }



    useEffect(() => {
        console.log('change id',id)
        apiGet(id)
    }, [id]);

    return (
        <div className=''>
            {
                showReports.length>0&&
                <CRMModal
                    title="Passenger reports"
                    onClose={()=>{
                        setShowReports([])
                    }}
                >
                    <div>
                        <ol>
                            {showReports.map((item)=>{
                                return <li>                                            
                                    <p className='text-start'><small><b><UserReportsNames id={item.report_type}/></b>: {item.comment}</small></p>
                                    <p className="text-end">
                                        <small>
                                            <i><FormatedDate type="basic">{item.report_date}</FormatedDate></i>
                                        </small>
                                    </p>
                                </li>
                            })} 
                        </ol>
                    </div>
                </CRMModal>
            }
            <div className="row justify-content-between">
                <div className="col-auto">
                    <button className="btn btn-primary btn-sm my-3" onClick={
                        ()=>{navigate(-1)}
                    }>
                        back
                    </button>
                </div>
                <div className="col-auto">
                    <div className="row justify-content-end">
                        {
                            profile.active_reports>0&&
                            <div className="col-auto">
                            <button className={`btn btn-danger btn-sm my-3`} onClick={
                                    ()=>{
                                        setShowReports(profile.reports)
                                    }
                                }>
                                    <i className="fa fa-flag me-1"></i> {profile.active_reports} reports
                                </button>
                            </div>
                        }                       
                    </div>
                </div>
            </div> 
            {/* todo backButton */}
            <ProfileHeader 
                user={profile.user}
            />            
            {
                profile.recommends>0&&profile.rides>0&&   
                <div className="my-3">
                    <h6 className="text-center">
                        {parseInt(profile.recommends/profile.rides*100)}% of passenger recommend
                    </h6>
                </div>
            }
            <ReviewsList 
                list={profile.reviews?profile.reviews:[]}        
                loading={false}
                noDataText="The passenger doesn't have reviews"
                loadingText="Loading reviews"                
                onItemClick={
                    (model)=>{
                        handleItemClick(model)
                    }
                }
            />                            
        </div>
    );
}

export default ProfilePassenger;
