import React,{useState,useEffect} from 'react';

import UserImage from '../../../components/UserImage';
import DriverLevel from '../../../components/DriverLevel';

import FormatedRate from '../../../components/FormatedRate';


const ProfileHeader = (props) => {
    const {
        user
    }=props

    const {
        name,
        last_name,
        rides,
        id,
        rating=0,
        level
    } = user

    return (
        <div className='p-2 bg-white w-100 border-bottom'>
            {console.log('pre profile',user)}
            <div className='row justify-content-center align-items-center'>
                <div className="col-auto">
                    <UserImage 
                        user={user}
                    />
                </div>
                <div className="col">
                    {/* name and rate row */}
                    <div className="row">
                        <div className="col">
                            <h6 className='text-start'>{name} {last_name}</h6>
                        </div>
                        <div className="col-auto">
                            <h6>
                                <i className="fa fa-star mr-3"></i>
                                <FormatedRate>
                                    {parseInt(rating)}
                                </FormatedRate>
                            </h6>
                        </div>
                    </div>
                    {/* trips info and lever row */}
                    <div className="row">
                        <div className="col">
                            <p className='text-start'>{`${rides} Trips · Passenger #${id}`}</p>
                        </div>
                        <div className="col-auto">
                            <DriverLevel 
                                level={level}                        
                            />
                        </div>
                    </div>
                                
                </div>           
            </div>
        </div>
    );
}

export default ProfileHeader;
