import { configureStore } from '@reduxjs/toolkit'

import auth from '../reducers/auth'
import navbar from '../reducers/navbar'
import messages from '../reducers/messages'
 
const store = configureStore({
    reducer: {   
        'auth':auth,
        'navbar':navbar,
        'messages':messages
    }
}) 

export default store