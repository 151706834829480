import React ,{useEffect} from 'react';

import moment from 'moment';

const FormatedDate = (props) => {
    const {
        children,
        type="relative"
    } = props    

    useEffect(() => {
        moment.locale('en')
    }, []);

    const showDate = (children,type)=>{ 
        let childDate = moment.utc(children).local().fromNow()
        if(type=="basic"){
            childDate = moment.utc(children).format('MMMM Do YYYY, h:mm:ss a');
        }
            
        return childDate
    }

    return (
        <span>
            {showDate(children,type)}
        </span>
    );
}

export default FormatedDate;
