import React from 'react';

import UserImage from './UserImage';
import FormatedDate from './FormatedDate';

const ReviewsItem = (props) => {
    const {
        model,
        type,
        onClick=undefined,
        rate
    }=props

    const {
        name,
        last_name,        
        id,        
    } = model

    return (
        <div className='row my-2 py-2'>
            <div className="col-12 position-relative">  
                <div className="review-image-fix">
                    <UserImage 
                        user={model}
                        userType={type}
                        size="review"
                    />
                </div>
                <div className="bg-gray-soft p-2">
                    <div className="row justify-content-start">
                        <div className="col-auto">
                            <h6>
                                <i className="fa fa-star me-1">
                                </i>
                                {rate.rating}
                            </h6>
                        </div>
                        <div className="col text-start">
                            <h6>
                                {name} {last_name}
                            </h6>
                        </div>
                    </div>
                    <div className="row my-1">
                        <div className="col-12">
                            <p>
                                {rate.rate_review}
                            </p>
                        </div>
                    </div>
                    <div className="row justify-content-start">
                        <p className='text-end'>
                            <i>
                                <FormatedDate
                                    type="basic"
                                >
                                    {rate.rate_date}
                                </FormatedDate>
                            </i>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ReviewsItem;
