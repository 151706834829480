import React from 'react';

const PaginationFooter = (props) => {
    const {    
        currentPage,
        totalPages,
        onNext,
        onPrev
    } = props

    return (
        <div className='row justify-content-end'>
            <div className="col-auto">
                <p>
                    page {currentPage} of {totalPages}
                </p>
            </div>
            <div className="col-auto">
                <div className="btn-group" role="group">
                    <button 
                        onClick={()=>{ onPrev(currentPage-1) }}
                        type="button" 
                        disabled={currentPage==1}
                        className="btn-sm btn btn-secondary">
                            Prev
                    </button>

                    <button 
                        onClick={()=>{ onNext(currentPage+1) }}
                        type="button" 
                        disabled={currentPage==totalPages}
                        className="btn-sm btn btn-secondary">
                            Next
                    </button>
                </div>
            </div>           
        </div>
    );
}

export default PaginationFooter;
