import {
    SET_APP_ALERT_MESSAGE,
    SET_APP_INFO_MESSAGE,
    SET_APP_ERROR_MESSAGE,
    CLEAR_APP_MESSSAGE,
    SET_NAV
} from "../actions/types"

import  AppService from "../services/app.services"


export const setNav = (index) => (dispatch) => {
    dispatch({
        type:SET_NAV,
        payload:index
    })
}

export const setMessage = (message,type) => (dispatch) => {
    switch (type) {
        case "alert":
            dispatch({
                type:SET_APP_ALERT_MESSAGE,                
                payload:message
            })
            break;        
        case "error":
            dispatch({
                type:SET_APP_ERROR_MESSAGE,                
                payload:message
            })
            break;        
        case "info":
        default:
            dispatch({
                type:SET_APP_INFO_MESSAGE,                
                payload:message
            })
            break;
    }
}

export const clearMessage = () => (dispatch) => {
    dispatch({
        type:CLEAR_APP_MESSSAGE
    })
}   

/*General App Actions*/
export const getImage = (src,ac) => (dispatch) => {    
    return AppService.getImage(src,ac).then(
        (response)=>{            
            return Promise.resolve(src)
        },
        (error)=>{
            return Promise.reject(error)
        }
    )
}

