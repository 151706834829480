import C from "../constants"
import axios from "axios";
import authHeader from './auth-header';

const API_URL = C.url + "";

class RideServices {

    getRides(
        user,
        from,
        to,
        active,
        payment,
        page,
        row
    ) {
        return axios
        .get(API_URL + "/rides/index", { 
            params:{
                user,
                from,
                to,
                active,
                payment,
                page,
                row
            },
            headers:authHeader()
        }).then((response) => {           
            return response;              
        });
    }

    getPassengers(
        user,
        from,
        to,
        enabled,
        onlyReported,
        page,
        row
    ) {
        return axios
        .get(API_URL + "/passengers/index", { 
            params:{
                user,
                from,
                to,
                enabled,
                onlyReported,
                page,
                row
            },
            headers:authHeader()
        })
        .then((response) => {
            console.log("get ");
            console.log(response);  
            return response;              
        });
    }

    getDrivers(
        user,
        from,
        to,
        enabled,
        onlyReported,
        page,
        row
    ) {
        return axios
        .get(API_URL + "/drivers/index", { 
            params:{
                user,
                from,
                to,
                enabled,
                onlyReported,
                page,
                row
            },
            headers:authHeader()
        })
        .then((response) => {
            console.log("get",response);
            console.log(response);  
            return response;              
        });
    }

    getDriver(
        id,        
    ) {
        return axios
        .get(API_URL + "/drivers/"+id, {             
            headers:authHeader()
        })
        .then((response) => {
            console.log("get",response);            
            return response;              
        });
    }

    getPassenger(
        id,        
    ) {
        return axios
        .get(API_URL + "/passengers/"+id, {             
            headers:authHeader()
        })
        .then((response) => {
            console.log("get",response);            
            return response;              
        });
    }

    getReports(
        rides,
        passengers,
        drivers,
        from,
        to,
    ) {
        return axios
        .get(API_URL + "/reports/index", { 
            params:{
                rides,
                passengers,
                drivers,
                from,
                to,
            },
            headers:authHeader()
        })
        .then((response) => {
            console.log("get",response);
            console.log(response);  
            return response;              
        });
    }

    getConversationMessages(
        id,
        page,
        row
    ) {
        return axios
        .get(API_URL + "/conversations/messages-by-ride/"+id, { 
            params:{                
                page,
                row
            },
            headers:authHeader()
        })
        .then((response) => {
            console.log("get",response);
            console.log(response);  
            return response;              
        });
    }

    getRideStats(           
    ) {
        return axios
        .get(API_URL + "/rides/stats", {         
            headers:authHeader(),            
        })
        .then((response) => {
            console.log("get",response);
            console.log(response);  
            return response;              
        });
    }
    getEmails(    
        page,
        rows
    ) {
        return axios
        .get(API_URL + "/landing/emails", {         
            headers:authHeader(),
            params:{                
                page,
                rows
            },
        })
        .then((response) => {
            console.log("get",response);
            console.log(response);  
            return response;              
        });
    }
    getContactEmails(    
        page,
        rows
    ) {
        return axios
        .get(API_URL + "/landing/contact-emails", {         
            headers:authHeader(),
            params:{                
                page,
                rows
            },
        })
        .then((response) => {
            console.log("get",response);
            console.log(response);  
            return response;              
        });
    }

    validateDriver( 
        id,   
        validate
    ) {
        return axios
        .put(API_URL + "/drivers/"+id+"/validate",{
            validate
        },{         
            headers:authHeader()
        })
        .then((response) => {
            console.log("get",response);
            console.log(response);  
            return response;              
        });
    }
}

export default new RideServices();