import React from 'react';
import DriverLevel from '../../../components/DriverLevel';
import FormatedRate from '../../../components/FormatedRate';
import FormatedDate from '../../../components/FormatedDate';


import UserImage from '../../../components/UserImage';

const DriversListItem = (props) => {
    const {
        model,
        onUserClick,
        onShowReports
    } = props

    const {
        id,
        email,
        name,
        last_name,
        subtitle,
        image_sufix,
        lower_unaccent_name,
        lower_unaccent_subtitle,
        rides,
        cancelled,
        active_reports,
        total_reports,
        level,
        rating
    } = model

    const handleUserClick = (model)=>{
        if(onUserClick){
            onUserClick(model)
        }
    }

    return (
        <div className='my-1 p-2 row-list'>
            <div className="row justify-content-between align-items-center">
                <div className="col-auto">
                    <UserImage 
                        user={{
                            id,
                            image_sufix
                        }}
                        userType="driver"
                    />
                </div>
                <div className="col">
                    <div className="row justify-content-start my-1">
                        <div className="col-12 text-start">                            
                            <div className="d-inline-block">
                                <button 
                                    className='btn-sm btn btn-link'
                                    onClick={()=>{handleUserClick(model)}}
                                    >
                                    {name} {last_name} ({email})
                                </button>                            
                            </div>    
                        </div> 
                    </div>
                    <div className="row my-1">
                        <div className="col-12 text-start">
                            <div className="d-inline-block ms-2">
                                <h6><i className="fa fa-id-card-o me-1"></i>{id}</h6>
                            </div>                   
                            <div className="d-inline-block ms-1">
                                <h6><i className="fa fa-car mx-1"></i> {rides} rides <b className='ms-2 text-danger'><i className="fa fa-cross"></i>({cancelled} cancelled)</b> · </h6>
                            </div>                   
                            <div className="d-inline-block ms-1" onClick={()=>{
                                if(active_reports>0){
                                    onShowReports(model.reports)
                                }else{

                                }
                            }}>
                                <h6 className={`${active_reports>0?"cursor-pointer underline text-danger":""}`}><i className="fa fa-flag mx-1"></i> {active_reports} active reports <b className='ms-2'>({total_reports} total)</b></h6>
                            </div>                   
                        </div>
                        <div className="col-12">
                            <p className="text-end">
                                <small>
                                    <i>joined on <FormatedDate type="basic">{model.creation_date}</FormatedDate></i>
                                </small>
                            </p>
                        </div>
                    </div>
                </div>
                <div className="col-auto">
                    <h6>
                        <i className="fa fa-star me-2">
                        </i>
                        {/* Rate */}
                        <FormatedRate>
                            {parseInt(rating)}
                        </FormatedRate>
                    </h6>
                    <div className="my-1">
                        <DriverLevel 
                            level={level}
                        />
                    </div>
                </div>  
            </div>           
        </div>
    );
}

export default DriversListItem;
