import React from 'react';

import FormatedDate from '../../../components/FormatedDate'
import RideStatusText from '../../../components/RideStatusText'

const RideListItem = (props) => {
    const {
        model,
        onSeeMap,
        onSeeChat,
        onSeeReports,
        onPassengerClick,
        onDriverClick
    }=props

    const renderStatus = (status)=>{
        return status;
    }

    return (
        <div className='my-1 p-2 row-list'>
            <div className="row justify-content-between">
                <div className="col text-start">
                    <div className="d-inline-block me-1">
                        <h6>#{model.id}</h6>
                    </div>
                    <div className="d-inline-block me-1">
                        <h6><b><RideStatusText  rideCode={model.ride_status} /></b></h6>
                    </div>
                    <div className="d-inline-block me-2">
                        <h6><small><FormatedDate type="basic" >{ model.ride_time }</FormatedDate></small></h6>
                    </div>
                    <div className="d-inline-block">
                        <div className="btn-group" role="group">
                            <button 
                                onClick={()=>{ onSeeMap(model) }}
                                type="button" 
                                className="btn-sm btn btn-secondary">
                                    See map
                            </button>
                            <button 
                                onClick={()=>{ onSeeChat(model) }}
                                type="button" 
                                className="btn-sm btn btn-secondary">
                                    Chat
                            </button>
                            {model.driver_reports.length>0&&model.passenger_reports.length>0&&
                                <button 
                                    onClick={()=>{ onSeeReports(model) }}
                                    type="button" 
                                    className="btn-sm btn btn-danger">
                                        Reports
                                </button>
                            }                            
                        </div>
                    </div>
                </div>
                <div className="col-auto">
                    <h6>${model.final_price} <b>{model.currency_code}</b> <strong className='ms-1'>{model.preferred_payment_method==0?<i className="fa fa-money text-success"></i>:<i className="fa fa-credit-card text-primary"></i>}</strong> </h6>                    
                </div>  
            </div>
            <div className="row">
                <div className="col-12">
                    <p className='text-start'>
                        <i className="fa fa-circle me-1 text-gray"></i>{model.start_formatted_address}
                    </p>
                </div>
                <div className="col-12 mt-1">
                    <p className='text-start'>
                        <i className="fa fa-circle me-1 text-success"></i>{model.end_formatted_address}
                    </p>
                </div>
            </div>
            <div className="row">
                <div className="col-12 text-start">
                    <button className="btn-sm btn btn-outline-primary" onClick={()=>{
                        onPassengerClick(model.passenger)
                    }}>                        
                        <i className="fa fa-user me-1"></i> Passenger: {model.passenger.name} {model.passenger.last_name} ({model.passenger.email}) #{model.passenger.id}
                    </button>
                </div>
                {
                    model.id_driver&&
                    <div className="col-12 text-start mt-1">
                        <button className="btn-sm btn btn-outline-primary" onClick={()=>{
                            onDriverClick(model.driver)
                        }}>
                            <i className="fa fa-car me-1"></i> Driver: {model.driver.name} {model.driver.last_name} ({model.driver.email}) #{model.driver.id}
                        </button>
                    </div>
                }                
            </div>
        </div>
    );
}

export default RideListItem;
