import React from 'react';

const DrivingCompaniesContainer = (props) => {
    const {
        list=[]
    }= props

    return (
        <div className='row my-2 justify-content-center'>
            <div className="col-12 my-1">
                <h5 className="text-center">
                    Driving Services
                </h5>                
            </div>
            {
                list.length>0?
                    list.map((value)=>{
                        return <div className="col-3" key={value.id}>
                            <div className='card-driving'>
                                <h6 className="text-center">{value.company_name}</h6>
                            </div>
                        </div>
                    })
                :
                <div className="col-12 my-2">
                    <h6 className="text-center text-muted">
                        The driver doesn't use another driving service
                    </h6>
                </div>
            }
        </div>
    );
}

export default DrivingCompaniesContainer;
