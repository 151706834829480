import {
    SET_NAV
} from '../actions/types'

const initialState = {
    index:"HOME"
}

export default (state=initialState, action) => {
    const {
        type,
        payload
    } = action

    switch (type) {
        case SET_NAV:
            return {
                index:payload
            }
        default:
            return state
    }
}


