import React from 'react';

const BasicList = (props) => {
    const {
        list,
        renderFunction,
        loading=false,
        noDataText="No data",
        loadingText="Loading data"
    }= props

    const handleRenderFunction = (list)=>{        
        if(renderFunction){
            list.map((model)=>{                
                return renderFunction(model)
            })            
        }else{
            return <div className="col-12 my-2">
                <p>No render function is setted</p>
            </div>
        }
    }

    return (
        <div className='row basic-list justify-content-center'>
            <div className="col-12">
                {
                    loading?
                    <div className="col-12 p-2">
                        <p className='text-center'>
                            <i className="fa fa-spinner fa-spin mr-1"></i> {loadingText}
                        </p>
                    </div>:
                    list&&list.length!==0?
                        //handleRenderFunction(list)
                        list.map((model)=>{
                            return renderFunction(model)
                        })
                    :
                    <div className="col-12 p-2">
                        <p className='text-center'>
                            {noDataText}
                        </p>
                    </div>
                }
            </div>
        </div>
    );
}

export default BasicList;
