import React from 'react';

const TitleValue = (props) => {
    const {
        title,
        value,
        loading
    } =  props
    return (
        <div className='card-title-value h-100'>
            <h6 className="text-center">
                {title}                
            </h6>
            <h4 className='text-center '>
                <b>
                    {loading?<i className="fa fa-spinner fa-spin"></i>:value}
                </b>
            </h4>
        </div>
    );
}

export default TitleValue;
