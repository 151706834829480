import React from 'react';

const CRMModal = (props) => {
    const {
        title="",
        children,
        footer="",
        image=null,
        onClose      
    } = props

    return (
        <div className="crm-modal-container" onClick={()=>{onClose()}}>
            <div className='crm-modal' onClick={(e)=>(e.stopPropagation())}>
                <div className="crm-modal__close">
                    <h4 className='text-center text-white cursor-pointer' onClick={()=>{onClose()}}>
                        <i className="fa fa-times"></i>
                    </h4>
                </div>
                <div className="crm-modal__body">
                    {
                        title!==""&&
                        <div className="crm-modal__body__header">
                            <h4>{title}</h4>
                        </div> 
                    }
                    <div className="crm-modal__body__content">
                        {children}
                    </div>
                    {
                        footer!==""&&
                        <div className="crm-modal__body__footer">
                            {footer}
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}

export default CRMModal;
