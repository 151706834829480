import React,{useEffect,useState,useRef} from 'react';

import DriverGallery from './components/DriverGallery';
import DrivingCompaniesContainer from './components/DrivingCompaniesContainer';
import PlateContainer from './components/PlateContainer';
import ProfileHeader from './components/ProfileHeader';
import VideoContainer from './components/VideoContainer';
import SocialMediaContainer from './components/SocialMediaContainer';
import ImageContainer from './components/ImageContainer';

import UserReportsNames from '../../components/UserReportsNames';
import FormatedDate from '../../components/FormatedDate';

import CRMModal from '../../components/CRMModal';
import CRMModalImage from '../../components/CRMModalImage';

import ReviewsList from '../../components/ReviewsList';

import * as rideActions from '../../actions/rides.actions'
import * as appActions from '../../actions/app.actions'

import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import ReactPlayer from 'react-player/file';


import constants from '../../constants';

const ProfileDrivers = (props) => {
    const {id} = useParams()

    const dispatch = useDispatch()
    const navigate = useNavigate()
    //const [state, setstate] = useState(initialState);

    const profileInitState = {
        id:null,
        user:{
            'id':"#",
            'image_sufix':0,
            'level':0,
            'name':"",
            'last_name':"",
            'rides':"#",
            'rating':0.0
        },
        pictures:[],
        companies:[],
        description:null,
        recommend:null,
        facebook:null,
        twitter:null,
        whatsapp:null,
        plate_number:null,
        hasvideo:false,
        status_description:0,
        status_car:0,
        status_companies:0,
        video_sufix:-1,
        car_model:"",
        car_year:"",
        car_color:"",
        status_id:null,
        status_license:null,
        ready_to_ride_status:0
        /*id,
        email,
        name,
        last_name,
        subtitle,
        description,
        image_sufix,
        lower_unaccent_name,
        lower_unaccent_subtitle,
        rides,
        cancelled,
        active_reports,
        total_reports,
        level,
        rating,
        rates,
        gender,
        hasvideo,
        video_sufix,
        status_description,
        status_car,
        status_companies,                
        status_license,
        ready_to_ride_status,
        car_color,
        facebook,
        twitter,
        whatsapp,
        recommends*/
    }

    const [profile, setProfile] = useState(profileInitState);
    const [galleryIndex, setGalleryIndex] = useState(0);
    const [video, setVideo] = useState(null);
    const [showModal, setShowModal] = useState({
        context:"validate",
        title:"",
        show:false
    });
    const [modalImage, setModalImage] = useState(null);
    const [validateLoading, setValidateLoading] = useState(false);
    const [showReports, setShowReports] = useState([]);

    const apiGet = (userId)=>{  
        dispatch(
            rideActions.getDriver(userId)
        ).then(
            (response)=>{
                console.log('the response',response.driver)
                setProfile({
                    ...profile,
                    ...response.driver,
                    'user':{
                        'id':response.driver.id,
                        'image_sufix':response.driver.image_sufix,
                        'level':response.driver.level,
                        'name':response.driver.name,
                        'last_name':response.driver.last_name,
                        'rides':response.driver.rides,
                        'rating':response.driver.rating
                    }
                })
            },
            (error)=>{
                console.log('the error',error)
            }
        )
    }

    const apiValidateDriver = (id,valid)=>{
        setValidateLoading(true)
        dispatch(rideActions.validateDriver(id,valid)).then(
            ()=>{
                setProfile({
                    ...profile,
                    'ready_to_ride_status':valid
                })
                setShowModal({
                    ...showModal,
                    "show":false
                })
                setValidateLoading(false)
                dispatch(appActions.setMessage("The driver status has been updated"))
            },
            ()=>{
                setValidateLoading(false)
            }
        )
    }

    const handleGalleryNext = ()=>{
        
    }
    const handleGalleryBack = ()=>{

    }
    const handleDotClick = (step)=>{
        setGalleryIndex(step)
    }

    const handlePlateClick = ()=>{       
        dispatch(appActions.setMessage(profile.plate_number?profile.plate_number:"No plate"))        
    }
    const handleVideoClick = ()=>{
        if(profile.video_sufix>-1){
            setVideo(`${constants.resources.BASE_DRIVERS_VIDEO_CDN_URL}${profile.id}-${profile.video_sufix}.mp4`)
        }else{
            dispatch(appActions.setMessage('The driver doesn\'t have a video to play','info'))
        }
    }

    const handleItemClick = (model)=>{

    }
    const handleShowImage = (image)=>{
        setModalImage(image)
    }

    const renderMessage= (context)=>{
        switch (context) {
            case 'validate':
                return <div className=''>
                    <div className="row">
                        <div className="col-12">
                            <p>Do you want to {profile.ready_to_ride_status==1?"disable":"enable"} the driver to take rides?</p>
                        </div>
                    </div>
                    <div className="row justify-content-end my-3">
                        <div className="col-auto">
                            <button className='btn btn-small btn-dark'
                                onClick={()=>{
                                    setShowModal({
                                        show:false
                                    })
                                }}
                            >
                                cancel
                            </button>
                        </div>
                        <div className="col-auto">
                            <button className={`btn btn-small ${profile.ready_to_ride_status==1?"btn-danger":"btn-primary"}`}
                                disabled={validateLoading}
                                onClick={()=>{
                                    apiValidateDriver(id,profile.ready_to_ride_status==1?0:1)
                                }}
                            >
                                {validateLoading?"loading":profile.ready_to_ride_status==1?"disable driver":"enable driver"}
                            </button>
                        </div>
                    </div>
                </div>
            default:
                break;
        }
        
    }



    useEffect(() => {
        console.log('change id',id)
        apiGet(id)
    }, [id]);

    return (
        <div className=''>
            {
                video&&<CRMModal
                    onClose={()=>{setVideo(null)}}
                    title="Driver's video"
                >
                    <div className="p-1">
                        <ReactPlayer  controls url={video} />
                    </div>
                </CRMModal>
            }
            {
                showModal.show&&<CRMModal
                    onClose={()=>{setShowModal({...showModal,show:false})}}
                    title={showModal.title}
                >
                    {renderMessage(showModal.context)}
                </CRMModal>
            } 
            {
                modalImage&& <CRMModalImage 
                    image={modalImage}
                    onClose={()=>{setModalImage(null)}}
                />
            }
            {
                showReports.length>0&&
                <CRMModal
                    title="Driver reports"
                    onClose={()=>{
                        setShowReports([])
                    }}
                >
                    <div>
                        <ol>
                            {showReports.map((item)=>{
                                return <li>                                            
                                    <p className='text-start'><small><b><UserReportsNames type='driver' id={item.report_type}/></b>: {item.comment}</small></p>
                                    <p className="text-end">
                                        <small>
                                            <i><FormatedDate type="basic">{item.report_date}</FormatedDate></i>
                                        </small>
                                    </p>
                                </li>
                            })} 
                        </ol>
                    </div>
                </CRMModal>
            }

            <div className="row justify-content-between">
                <div className="col-auto">
                    <button className="btn btn-primary btn-sm my-3" onClick={
                        ()=>{navigate(-1)}
                    }>
                        back
                    </button>
                </div>
                <div className="col-auto">
                    <div className="row justify-content-end">
                        {
                            profile.active_reports>0&&
                            <div className="col-auto">
                            <button className={`btn btn-danger btn-sm my-3`} onClick={
                                    ()=>{
                                        setShowReports(profile.reports)
                                    }
                                }>
                                    <i className="fa fa-flag me-1"></i> {profile.active_reports} reports
                                </button>
                            </div>
                        }
                        <div className="col-auto">
                            <button disabled={profile.ready_to_ride_status==0?(profile.video_sufix==-1||profile.status_car==0||profile.status_companies==0||profile.status_description==0||profile.status_id==null||profile.status_license==null):false} className={`${profile.ready_to_ride_status==1?"btn-danger":"btn-primary"} btn btn-sm my-3`} onClick={
                                ()=>{
                                    setShowModal({
                                        "show":true,
                                        "context":'validate',
                                        "title":profile.ready_to_ride_status?"disable driver":"enable driver",
                                    })
                                }
                            }>
                                {profile.ready_to_ride_status==1?"disable driver":"enable driver"}
                            </button>
                        </div>
                    </div>
                </div>
            </div>       

            <ProfileHeader 
                user={profile.user}
            />
            <DriverGallery 
                elements={profile.pictures}
                step={galleryIndex}
                onBack={()=>{setGalleryIndex(galleryIndex==0?profile.pictures.length-1:galleryIndex-1)}}
                onNext={()=>{setGalleryIndex(galleryIndex==profile.pictures.length-1?0:galleryIndex+1)}}      
                onDotClick={(step)=>{setGalleryIndex(step)}}
            />            
            <div className="row justify-content-center py-2 row-minus">                
                <div className="col-4">
                    <PlateContainer 
                        plate={profile.plate_number&&profile.plate_number}
                        onClick={handlePlateClick}
                    />            
                </div>
                <div className="col-4">
                    <VideoContainer 
                        thumbnail={profile.id&&profile.video_sufix>-1?`${constants.resources.BASE_DRIVERS_VIDEO_SMALL_CDN_URL}${profile.id}-${profile.video_sufix}.jpg`:null}
                        onClick={handleVideoClick}
                    />
                </div>
            </div>
            <div className="row justify-content-center py-2 mt-3">                
                <div className="col-4">
                    <h6 className="text-center">
                        ID
                    </h6>
                    <ImageContainer
                        image={profile.status_id?`${constants.resources.BASE_DRIVERS_CDN_URL_ID}${profile.id}-${profile.status_id}.jpg`:null}
                        context="ID"
                        onClick={handleShowImage}
                    />                    
                </div>
                
                <div className="col-4">
                    <h6 className="text-center">
                        License
                    </h6>
                    <ImageContainer 
                        image={profile.status_license?`${constants.resources.BASE_DRIVERS_CDN_URL_LICENSE}${profile.id}-${profile.status_license}.jpg`:null}
                        context="License"
                        onClick={handleShowImage}
                    />
                </div>
            </div>
            <div className="my-3">
                <p className="text-center text-muted">
                    {profile.description}
                </p>
            </div>
            <div className="row my-2">
                <div className="col-12">
                    <h5>Car info</h5>
                </div>
                <div className="col-12 my-1 text-center">
                    {
                        profile.status_car?
                        <p>
                            {profile.carmodel.model_name} {profile.car_year} <i className="ms-1 fa fa-car" style={{color:profile.car_color}}></i>
                        </p>
                        :<p className="text-muted">There is no car information</p>
                    }
                </div>
            </div>
            <DrivingCompaniesContainer 
                list={profile.companies}            
            />
            <SocialMediaContainer 
                facebook={profile.facebook}
                twitter={profile.twitter}
                whatsapp={profile.whatsapp}
            />  
            {
                profile.recommends>0&&
                <div className="my-2">
                    <h6 className="text-center">
                        {profile.recommends/profile.rides*100}% of passenger recommend
                    </h6>
                </div>
            }
            <ReviewsList 
                list={profile.reviews?profile.reviews:[]} 
                type="passenger"       
                loading={false}
                noDataText="The driver doesn't have reviews"
                loadingText="Loading data"
                onItemClick={
                    (model)=>{
                        handleItemClick(model)
                    }
                }
            />                
        </div>
    );
}

export default ProfileDrivers;
