import React from 'react';

import NavBar from '../../components/NavBar';

import {Routes,Route} from "react-router-dom";

import Home from '../Home';
import Rides from '../Rides'
import Drivers from '../Drivers'
import Passengers from '../Passengers';
import ProfilePassengers from '../ProfilePassengers';
import ProfileDrivers  from '../ProfileDrivers'
import CRMModal from '../../components/CRMModal';
import LandingEmails from '../LandingEmails';
import LandingContact from '../LandingContact'

import * as appActions from '../../actions/app.actions'

import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

const Auth = (props) => {
    
    const dispatch = useDispatch()
    const message = useSelector((state)=>(state.messages.message))

    return (
        <div>
            {
                message!==""&&<CRMModal
                    onClose={()=>{
                        dispatch(appActions.clearMessage())
                    }}
                >
                    <div className="p-3">
                        <p>{message}</p>
                    </div>
                </CRMModal>
            }
            <NavBar></NavBar>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <Routes>
                            <Route path='/' element={<Home />}></Route>   
                            <Route path='/rides' element={<Rides />}></Route>   
                            <Route path='/drivers' element={<Drivers />}></Route>   
                            <Route path='/passengers' element={<Passengers />}></Route>   
                            <Route path='/landingemails' element={<LandingEmails />}></Route>   
                            <Route path='/landingcontact' element={<LandingContact />}></Route>   
                            <Route path='/driver/:id' element={<ProfileDrivers />}></Route>   
                            <Route path='/passenger/:id' element={<ProfilePassengers />}></Route>   
                        </Routes>                         
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Auth;
