import React from 'react';

const RideStatusText = ({rideCode}) => {
    
    const rideStatus = {
        "1":"looking for drivers",
        "2":"Driver coupled",
        "3":"Driver is on the way",
        "4":"Driver is on the pickup point",
        "5":"Ride in progress",
        "6":"Ride ready to rate",
        "100":"Ride Finished OK",
        "101":"Ride Cancelled",
        "201":"Ride Cancelled by Passenger in step 1",
        "202":"Ride Cancelled by Passenger in step 2",
        "203":"Ride Cancelled by Passenger in step 3",
        "204":"Ride Cancelled by Passenger in step 4",
        "301":"Ride Cancelled by Driver in step 1",
        "302":"Ride Cancelled by Driver in step 2",
        "303":"Ride Cancelled by Driver in step 3",
        "304":"Ride Cancelled by Driver in step 4"
    }

    return (
        <span>
            {rideStatus[rideCode]}
        </span>
    );
}

export default RideStatusText;
