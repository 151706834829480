import React from 'react';

import ReviewsItem from './ReviewsItem';

const ReviewsList = (props) => {
    const {
        list,    
        type="driver",    
        loading=false,
        noDataText="No data",
        loadingText="Loading data",
        onItemClick=undefined
    }= props


    return (
        <div className='row review-list justify-content-center col-12'>
            {console.log('list',list)}
            {
                loading?
                <div className="col-12 p-2">
                    <p className='text-center'>
                        <i className="fa fa-spinner fa-spin mr-1"></i> {loadingText}
                    </p>
                </div>:
                list&&list.length!==0?
                    list.map(({ride,rate_review,rate_date,rating})=>{
                        console.log(ride)
                        return <ReviewsItem 
                            //todo: add key
                            key={ride.id}
                            model={ride.passenger?ride.passenger:ride.driver}
                            type={type}
                            rate={{
                                rate_review,
                                rate_date,
                                rating
                            }}
                            onClick={(model)=>{
                                if(onItemClick){
                                    onItemClick(model)
                                }
                            }}
                        />
                    })
                :
                <div className="col-12 p-2">
                    <p className='text-center'>
                        {noDataText}
                    </p>
                </div>
            }
        </div>
    );
}

export default ReviewsList;
