import React,{useEffect,useState} from 'react';
import LoginForm from './components/LoginForm';
import RequestPasswordForm from './components/RequestPasswordForm';

import * as authActions from '../../actions/auth.actions'
import logo from '../../assets/logo.jpg'

import { useDispatch } from 'react-redux';

const Login = (props) => {
    const dispatch = useDispatch()
    const [showView, setShowView] = useState("login")   

    const handleLogin = (params,onSuccess,onError)=>{
        
        const {
            email,
            password
        }= params

        dispatch(
            authActions.login(email,password)
        ).then(
            (response)=>{
                onSuccess("Creating sesion")
            },
            (error)=>{
                onError(error)
            }
        )

    }
    const handleChangePasswordRequest = (params,onSuccess,onError)=>{
        
        const {
            email,            
        }= params

        dispatch(
            authActions.passwordresetrequest(email)
        ).then(
            (response)=>{
                onSuccess("An email to recover password has been sent to "+email)
            },
            (error)=>{
                onError(error)
            }
        )

    }

    return (
        <div className=''>
            <div className="container my-5">
                <div className="row justify-content-center">                    
                    <div className="col-md-4 card py-2">
                        <div className="bg-white w-100 text-center">
                            <img src={logo} alt="" className='logo' />
                            <h3 className="text-center mt-2 mb-3">
                                Buscaride CRM
                            </h3>
                            {
                                showView=="login"?
                                <LoginForm 
                                    onSubmit={handleLogin}
                                    onRecoveryPasswordClick={()=>{
                                        setShowView("recoverPassword")
                                    }}
                                />
                                :
                                <RequestPasswordForm 
                                    onSubmit={handleChangePasswordRequest}
                                    onBack={()=>{
                                        setShowView("login")
                                    }}
                                />
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login;
