import {
    SET_APP_ALERT_MESSAGE,
    SET_APP_INFO_MESSAGE,
    SET_APP_ERROR_MESSAGE,
    CLEAR_APP_MESSSAGE
} from "../actions/types"

const initialState = {
    message:"",
    type:"info"
}

export default (state=initialState, actions)=>{
    const {
        type,
        payload
    } = actions

    switch(type){
        case SET_APP_INFO_MESSAGE:
            return {                
                message:payload,
                type:"info"
            }        
        case SET_APP_ALERT_MESSAGE:
            return {                
                message:payload,
                type:"alert"
            }        
        case SET_APP_ERROR_MESSAGE:
            return {                
                message:payload,
                type:"error"
            }        
        case CLEAR_APP_MESSSAGE:
            return initialState        
        default:
            return state                    
    }

}