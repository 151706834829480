export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";

export const SET_APP_INFO_MESSAGE = "SET_APP_INFO_MESSAGE"
export const SET_APP_ALERT_MESSAGE = "SET_APP_ALERT_MESSAGE"
export const SET_APP_ERROR_MESSAGE = "SET_APP_ERROR_MESSAGE"
export const CLEAR_APP_MESSSAGE = "CLEAR_APP_MESSSAGE"

export const SET_NAV = "SET_NAV"
