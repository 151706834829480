import {
} from "../actions/types"

import { errorhandler } from "../helpers/errorHandler";

import RideServices from "../services/ride.services";

export const getRides = (
    user,
    fromDate,
    toDate,
    active,
    payment,
    page,
    rows=10
) => (dispatch) => {
    return RideServices.getRides(
        user,
        fromDate,
        toDate,
        active,
        payment,
        page,
        rows
    ).then(
        (response)=>{
            console.log(response)
            return Promise.resolve(response.data.rides)
        },
        (error)=>{
            console.log(error)
            const errorMessage = errorhandler(error,dispatch,true)
            return Promise.reject(errorMessage)
        }
    )
}

export const getPassengers = (
    user,
    fromDate,
    toDate,
    enabled,
    onlyReported,
    page,
    rows=10
) => (dispatch) => {
    return RideServices.getPassengers(
        user,
        fromDate,
        toDate,
        enabled,
        onlyReported,
        page,
        rows
    ).then(
        (response)=>{
            return Promise.resolve(response.data.passengers)
        },
        (error)=>{
            const errorMessage = errorhandler(error,dispatch,true)
            return Promise.reject(errorMessage)
        }
    )
}

export const getDrivers = (
    user,
    fromDate,
    toDate,
    enabled,
    onlyReported,
    page,
    rows=10
) => (dispatch) => {
    return RideServices.getDrivers(
        user,
        fromDate,
        toDate,
        enabled,
        onlyReported,
        page,
        rows
    ).then(
        (response)=>{
            return Promise.resolve(response.data.drivers)
        },
        (error)=>{
            const errorMessage = errorhandler(error,dispatch,true)
            return Promise.reject(errorMessage)
        }
    )
}
export const getConversations = (
    id, 
    page,
    rows=10
) => (dispatch) => {
    return RideServices.getConversationMessages(
        id,        
        page,
        rows
    ).then(
        (response)=>{
            return Promise.resolve(response.data.messages)
        },
        (error)=>{
            const errorMessage = errorhandler(error,dispatch,true)
            return Promise.reject(errorMessage)
        }
    )
}

export const getDriver = (
    driverId
) => (dispatch) => {
    return RideServices.getDriver(
        driverId
    ).then(
        (response)=>{
            return Promise.resolve(response.data)
        },
        (error)=>{
            const errorMessage = errorhandler(error,dispatch,true)
            return Promise.reject(errorMessage)
        }
    )
}
export const getPassenger = (
    passengerId
) => (dispatch) => {
    return RideServices.getPassenger(
        passengerId
    ).then(
        (response)=>{
            return Promise.resolve(response.data)
        },
        (error)=>{
            const errorMessage = errorhandler(error,dispatch,true)
            return Promise.reject(errorMessage)
        }
    )
}

export const getEmails = (    
    page,
    rows
) => (dispatch) => {
    return RideServices.getEmails(               
        page,
        rows
    ).then(
        (response)=>{
            return Promise.resolve(response.data)
        },
        (error)=>{
            const errorMessage = errorhandler(error,dispatch,true)
            return Promise.reject(errorMessage)
        }
    )
}

export const getContactEmails = (    
    page,
    rows
) => (dispatch) => {
    return RideServices.getContactEmails(               
        page,
        rows
    ).then(
        (response)=>{
            return Promise.resolve(response.data)
        },
        (error)=>{
            const errorMessage = errorhandler(error,dispatch,true)
            return Promise.reject(errorMessage)
        }
    )
}

export const getReports = (
    rides,
    passengers,
    drivers,
    fromDate,
    toDate
) => (dispatch) => {
    return RideServices.getReports(
        rides,
        passengers,
        drivers,
        fromDate,
        toDate
    ).then(
        (response)=>{
            return Promise.resolve(response.data)
        },
        (error)=>{
            const errorMessage = errorhandler(error,dispatch,true)
            return Promise.reject(errorMessage)
        }
    )
}

export const getRideStats = (  
) => (dispatch) => {
    return RideServices.getRideStats(        
    ).then(
        (response)=>{
            return Promise.resolve(response.data.stats)
        },
        (error)=>{
            const errorMessage = errorhandler(error,dispatch,true)
            return Promise.reject(errorMessage)
        }
    )
}

export const validateDriver = (  
    id,
    validate
) => (dispatch) => {
    return RideServices.validateDriver(   
        id,
        validate     
    ).then(
        (response)=>{
            return Promise.resolve(response.data.stats)
        },
        (error)=>{
            const errorMessage = errorhandler(error,dispatch,true)
            return Promise.reject(errorMessage)
        }
    )
}
