import React,{useEffect,useState,useRef} from 'react';

import { useDispatch } from 'react-redux';

import * as generalActions from '../actions/app.actions'
import c from '../constants'

const UserImage = (props) => {
    const {
        user,
        userType="passenger",
        size="md"
    } = props
    const dispatch = useDispatch()

    const abortC = useRef(null)

    const [loading, setLoading] = useState(true);
    const [src, setSrc] = useState('');
    useEffect(() => {
        console.log('use rprofile',user)
        if(user.image_sufix>0&&user.id!==undefined&&user.id!=="#"){                        
            getImage(3)
        }else{
            setLoading(false)
        }
        return ()=>{
            if(abortC.current){
                abortC.current.abort()
            }
        }
    }, []);

    useEffect(() => {
        if(user.id!=="#"&&user.id!==undefined){
            console.log('get image',user)
            setLoading(true)
            getImage(3)
            
        }        
    }, [user.id,user.imageSufix]);

    const getImage = (tries)=>{    
        if(abortC.current == null){
            abortC.current = new AbortController()
        }  
        dispatch(generalActions.getImage(`${userType=="passenger"?c.resources.BASE_PASSENGERS_CDN_URL:c.resources.BASE_DRIVERS_CDN_URL}${user.id}-${user.image_sufix>0?user.image_sufix:0}.jpg`,abortC.current)).then(
            (response)=>{                
                setSrc(response)
                setLoading(false)
            },
            (error)=>{   
                console.log('imageError',error)             
                if(tries>0){
                    setTimeout(() => {
                        getImage(--tries)
                    }, 5000);
                }else{
                    setSrc('')
                    setLoading(false)
                }                 
            }
        )
    } 
    

    return (
        <div className={`user-image user-image--${size}`}>
            {
                loading?
                    <div className="user-image__container">
                        <h5 className="user-image__container__icon">
                            <i className="fa fa-spinner fa-spin"></i>
                        </h5>
                    </div>
                :src!==""?
                <img src={src} alt="user image" className='w-100 h-100' />
                :
                <div className="user-image__container">
                    <h5 className="user-image__container__icon">
                        <i className="fa fa-user"></i>
                    </h5>
                </div>
            }
        </div>
    );
}

export default UserImage;
