import React,{useState,useEffect,useRef} from 'react';
import { useDispatch,useSelector } from 'react-redux';


import * as appActions from '../../actions/app.actions'
import * as ridesActions from '../../actions/rides.actions'

import RidesSearchNav from './components/RidesSearchNav';
import RideListItem from './components/RideListItem';
import BasicList from '../../components/BasicList';
import PaginationFooter from '../../components/PaginationFooter';
import CRMModal from '../../components/CRMModal';
import CRMMap from '../../components/CRMMap';
import FormatedDate from '../../components/FormatedDate';
import UserReportsNames from '../../components/UserReportsNames';
import MessageItem from '../../components/MessageItem';
import CRMGoogleMap from '../../components/CRMGoogleMap';

import { useNavigate } from 'react-router-dom';

const LandingEmails = (props) => {    
    const {

    }=props

    const navigate = useNavigate()

    const dispatch = useDispatch()

    const searchParamsInitialState = {
        "user":"",
        "fromDate":"",
        "toDate":"",
        "active":false,
        "payment":"*"
    }
    const paginationInitialState = {
        "currentPage":1,
        "totalPages":1
    }

    
    const [searchParams, setSearchParams] = useState(searchParamsInitialState);
    const [pagination, setPagination] = useState(paginationInitialState);
    
    const [rides, setRides] = useState({
        "list":[],
        "loading":false
    });

    const searchParamsRef = useRef(searchParamsInitialState)

    const getEmails = (page,onFinish) => {
      

        setRides({
            ...rides,
            'loading':true
        })

        dispatch(ridesActions.getEmails(            
            page
        )).then(
            (response)=>{
                console.log('response on index',response)
                console.log(response.emails.data)
                setRides({
                    'list':response.emails.data,
                    'loading':false
                })
                //TODO total pages
                setPagination({
                    ...pagination,
                    "currentPage":response.emails.current_page,
                    "totalPages":response.emails.last_page
                })
                onFinish(true)
            },
            (error)=>{
                //TODO handle Error
                setPagination({
                    ...pagination,
                    "currentPage":1,
                    "totalPages":1
                })
                setRides({
                    ...rides,
                    'list':[],
                    'loading':false
                })
                onFinish(false)
            }
        )
    }    

    useEffect(() => {
        dispatch(
            appActions.setNav("EMAILS")
        )
        getEmails(1,(p)=>{console.log('First call')})
    }, []);

    useEffect(() => {
        console.log('update search params',searchParams)
        searchParamsRef.current = searchParams
    }, [searchParams]);
    


    return (        
        <div className='row col-12 justify-content-center'> 
            <div className="col-12 my-2">
                <h4>
                    Users wanting to get updated about buscaride.
                </h4>    
            </div>     
            <div className="row justify-content-between">
                <div className="col-auto">
                    <button className="btn btn-primary btn-sm my-3" onClick={
                        ()=>{navigate(-1)}
                    }>
                        back
                    </button>
                </div>               
            </div>                  
            <BasicList 
                list={rides.list}
                loading={rides.loading}
                noDataText={"there is no emails"}
                loadingText={"loading emails..."}
                renderFunction={(model)=>{                    
                    return <div key={model.id} className='row my-1 justify-content-center'>
                        <div className="col-8">
                            <h6 className="text-start">
                                {model.email}
                            </h6>
                            <p className="text-end">
                                <FormatedDate type="basic"> 
                                    {model.updated_at}
                                </FormatedDate>                                
                            </p>
                        </div>                        
                    </div>                     
                }}
            />
            
            <PaginationFooter
                currentPage={pagination.currentPage}
                totalPages={pagination.totalPages}
                onNext={(page)=>{
                    setPagination({
                        ...pagination,
                        "currentPage":page
                    })
                    getEmails(                        
                        page,
                        ()=>{
                            console.log('pageNext',page)
                        }
                    )

                }}
                onPrev={(page)=>{
                    setPagination({
                        ...pagination,
                        "currentPage":page
                    })
                    getEmails(                        
                        page,
                        ()=>{
                            console.log('pagePrev',page)
                        }
                    )
                }}
            />
        </div>
    );
}

export default LandingEmails;
