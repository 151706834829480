import { errorhandler } from "../helpers/errorHandler";
import {
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT,
} from "./types";
  
import AuthService from "../services/auth.services";

export const login = (username, password) => (dispatch) => {
    return AuthService.login(username, password).then(
        (data) => {
            dispatch({
                type: LOGIN_SUCCESS,
                payload: { session: data },
            });
            return Promise.resolve();
        },
        (error) => {          
            const errorText = errorhandler(error,dispatch,true)                                                              
            return Promise.reject(errorText);
        }
    );
};

export const logout = () => (dispatch) => {
    return AuthService.logout().then(
        (data)=>{
            dispatch({
                type:LOGOUT
            })
            return Promise.resolve();
        },
        (error) => {           
            dispatch({
                type:LOGOUT
            })
            return Promise.reject();
        }  
    )      
};

export const passwordresetrequest = (email) => (dispatch) => {
    return AuthService.passwordresetrequest(email).then(
        (data) => {         
            return Promise.resolve(data);
        },
        (error) => {             
            const errorText = errorhandler(error,dispatch,true)                                                              
            return Promise.reject(errorText);
        }
    );
};

export const passwordreset = (token,email,password,password_confirm) => (dispatch) => {    
    return AuthService.passwordreset(token,email,password,password_confirm).then(
        (data)=>{                             
            return Promise.resolve(data);
        },
        (error) => {                                                         
            const errorText = errorhandler(error,dispatch,true)                                                              
            return Promise.reject(errorText);
        }  
    )      
};

