import React,{useState,useEffect} from 'react';

import TitleValue from '../../components/TitleValue';
import SectionButton from '../../components/SectionButton';

import * as appActions from "../../actions/app.actions"
import * as rideActions from "../../actions/rides.actions"

import { useDispatch } from 'react-redux';




const Home = (props) => {

    const dispatch = useDispatch()

    const [loadingStats, setLoadingStats] = useState(false);
    const [stats, setStats] = useState({
        "ride_001":0,
        "ride_002":0,
        "ride_003":0,
        "ride_004":0,
        "ride_005":0,
        "ride_006":0,
        "ride_100":0,
        "ride_101":0,
        "ride_201":0,
        "ride_202":0,
        "ride_203":0,
        "ride_204":0,
        "ride_301":0,
        "ride_302":0,
        "ride_303":0,
        "ride_304":0
    });

    const getRideStats = ()=>{
        setLoadingStats(true)
        dispatch(rideActions.getRideStats()).then(
            (response)=>{
                setStats({
                    ...response
                })
                console.log(response)
                setLoadingStats(false)
            },
            (error)=>{
                setLoadingStats(false)
            }
        )
    }

    useEffect(() => {
        dispatch(
            appActions.setNav("HOME")
        )        
        getRideStats()
    }, []);

    return (
        <div className='row'>
            <div className="col-12 my-3 text-center">
                <h3>Rides Stats</h3>
            </div>
            <div className="col-12">
                <div className="row justify-content-center h-100">
                    <div className="col-md my-1">
                        <TitleValue 
                            title="Looking for drivers"
                            value={stats.ride_001}
                            loading={loadingStats}
                        />
                    </div>                
                    <div className="col-md my-1">
                        <TitleValue 
                            title="Driver coupled"
                            value={stats.ride_002}
                            loading={loadingStats}
                        />
                    </div>                
                    <div className="col-md my-1">
                        <TitleValue 
                            title="Driver is on the way"
                            value={stats.ride_003}
                            loading={loadingStats}
                        />
                    </div>                
                    <div className="col-md my-1">
                        <TitleValue 
                            title="Driver is on the pickup point"
                            value={stats.ride_004}
                            loading={loadingStats}
                        />
                    </div>                
                    <div className="col-md my-1">
                        <TitleValue 
                            title="Ride in progress"
                            value={stats.ride_005}
                            loading={loadingStats}
                        />
                    </div>                
                    <div className="col-md my-1">
                        <TitleValue 
                            title="Ride ready to rate"
                            value={stats.ride_006}
                            loading={loadingStats}
                        />
                    </div>  
                </div>
            </div>
            <div className="col-12 mb-3 row justify-content-center">                                             
                <div className="col-md-4 my-1">
                    <TitleValue 
                        title="Rides Finished OK"
                        value={stats.ride_100}
                        loading={loadingStats}
                    />
                </div>                
                <div className="col-md-4 my-1">
                    <TitleValue 
                        title="Rides Cancelled"
                        value={stats.ride_101}
                        loading={loadingStats}
                    />
                </div>        
                <div className="col-12"></div>        
                <div className="col-md-3 my-1">
                    <TitleValue 
                        title="Rides Cancelled by Passenger in step 1"
                        value={stats.ride_201}
                        loading={loadingStats}
                    />
                </div>                
                <div className="col-md-3 my-1">
                    <TitleValue 
                        title="Rides Cancelled by Passenger in step 2"
                        value={stats.ride_202}
                        loading={loadingStats}
                    />
                </div>                
                <div className="col-md-3 my-1">
                    <TitleValue 
                        title="Rides Cancelled by Passenger in step 3"
                        value={stats.ride_203}
                        loading={loadingStats}
                    />
                </div>                
                <div className="col-md-3 my-1">
                    <TitleValue 
                        title="Rides Cancelled by Passenger in step 4"
                        value={stats.ride_204}
                        loading={loadingStats}
                    />
                </div>   
                <div className="col-12"></div>                 
                <div className="col-md-3 my-1">
                    <TitleValue 
                        title="Rides Cancelled by Driver in step 1"
                        value={stats.ride_301}
                        loading={loadingStats}
                    />
                </div>                
                <div className="col-md-3 my-1">
                    <TitleValue 
                        title="Rides Cancelled by Driver in step 2"
                        value={stats.ride_302}
                        loading={loadingStats}
                    />
                </div>                
                <div className="col-md-3 my-1">
                    <TitleValue 
                        title="Rides Cancelled by Driver in step 3"
                        value={stats.ride_303}
                        loading={loadingStats}
                    />
                </div>                
                <div className="col-md-3 my-1">
                    <TitleValue 
                        title="Rides Cancelled by Driver in step 4"
                        value={stats.ride_304}
                        loading={loadingStats}
                    />
                </div>                
            </div>
            <div className="col-12 my-5 row justify-content-center">
                <div className="col-6 col-md-4 my-2">
                    <SectionButton 
                        title="Rides"
                        extraClass="w-100"
                        to="/rides"
                    />
                </div>
                <div className="col-6 col-md-4 my-2">
                    <SectionButton 
                        title="Passenger"
                        extraClass="w-100"
                        to="/passengers"
                    />
                </div>
                <div className="col-6 col-md-4 my-2">
                    <SectionButton 
                        title="Drivers"
                        extraClass="w-100"
                        to="/drivers"
                    />
                </div>               
                <div className="col-6 col-md-4 my-2">
                    <SectionButton 
                        title="Landing Emails"
                        extraClass="w-100"
                        to="/landingemails"
                    />
                </div>               
                <div className="col-6 col-md-4 my-2">
                    <SectionButton 
                        title="Landing Contact Emails"
                        extraClass="w-100"
                        to="/landingcontact"
                    />
                </div>               
            </div>
        </div>
    );
}

export default Home;
