import React,{useEffect,useState} from 'react';
import { formValidateField } from '../../../helpers/formvalidation'; 
import LocalMessage from '../../../components/LocalMessage';

const RecoverPasswordForm = (props) => {
    const {
        onSubmit,
        onGoLogin,        
    } = props
    
    const formInitialState = {
        "email":{
            "value":"",
            "name":"email",
            "error":""
        },
        "password":{
            "value":"",
            "name":"password",
            "error":""
        },
        "password_confirm":{    
            "value":"",
            "name":"password_confirm",
            "error":""
        }
    }

    const [form, setForm] = useState(formInitialState);
    const [loading, setLoading] = useState(false);
    const [goLoginEnabled, setGoLoginEnabled] = useState(false);
    const [message,setMessage] = useState({
        value:"",
        type:""
    });
    

    const handleFormChange = (e)=>{        
        const {
            name,
            value
        } = e.target
        
        setForm({
            ...form,
            [name]:{
                ...form[name],
                value
            }
        })
    }

    const handleGoLogin = (e) =>{        
        if(onGoLogin){
            onGoLogin()
        }
    }

    const handleSubmit = (e)=>{
        e.preventDefault()     
        setMessage({
            value:"",
            type:""
        })
        setLoading(true)
        
        const {
            password,
            password_confirm,
            email
        } = form

        let formToValidate = {
            "password":{
                ...password,                
                "error":""
            },
            "email":{
                ...email,                
                "error":""
            },
            "password_confirm":{    
                ...password_confirm,                
                "error":""
            }
        }
        let valid = true

        if(!formValidateField(email.value,'email')){
            formToValidate = {
                ...formToValidate,
                'email':{
                    ...email,
                    "error":"The email is not valid"
                }
            }
            valid = false
        }  

        if(!formValidateField(email.value,'required')){
            formToValidate = {
                ...formToValidate,
                'email':{
                    ...email,
                    "error":"The email is required"
                }
            }
            valid = false
        }
         

        if(!formValidateField(password.value,'required')){
            formToValidate = {
                ...formToValidate,
                'password':{
                    ...password,
                    "error":"The password is required"
                }
            }
            valid = false
        }   
        if(!formValidateField(password_confirm.value,'required')){
            formToValidate = {
                ...formToValidate,
                'password_confirm':{
                    ...password_confirm,
                    "error":"The password confirmation is required"
                }
            }
            valid = false
        }
        if(password.value !== password_confirm.value){
            formToValidate = {
                ...formToValidate,
                'password_confirm':{
                    ...password_confirm,
                    "error":"The password confirmed is not equal to the password"
                }
            }
            valid = false
        }
        
        if(valid){
            if(onSubmit){
                onSubmit(
                    {                        
                        "password":password.value,
                        "password_confirmation":password_confirm.value,
                        "email":email.value,
                    },
                    (success)=>{
                        setLoading(false)
                        setGoLoginEnabled(true)
                    },
                    (error)=>{
                        setLoading(false)
                        setMessage({
                            "value":error,
                            "type":"error"
                        })
                    }
                )
            }
        }else{
            setForm(formToValidate)
            setLoading(false)
        }
    }

    return (        
        <form onSubmit={handleSubmit}>
            <div className="form-group row">
                <div className="col-12 text-center">                   
                    <h4>
                        Recover Password
                    </h4>
                </div>
            </div>
            {!goLoginEnabled?
            <div className="my-2">
                <div className="form-group my-1">
                    <label htmlFor="email">Email</label>
                    <input 
                        name='email' 
                        placeholder='email' 
                        className='form-control form-control-sm' 
                        type="email" 
                        onChange={handleFormChange} 
                    />
                    {form.email.error!==""&&<p className='text-danger'>{form.email.error}</p>}
                </div>

                <div className="form-group my-1">
                    <label htmlFor="password">Password</label>
                    <input 
                        name='password' 
                        placeholder='password' 
                        className='form-control form-control-sm' 
                        type="password" 
                        onChange={handleFormChange} 
                    />
                    {form.password.error!==""&&<p className='text-danger'>{form.password.error}</p>}
                </div>
                <div className="form-group my-1">
                    <label htmlFor="password_confirm">Confirm Password</label>
                    <input 
                        name='password_confirm' 
                        placeholder='confirm password' 
                        className='form-control form-control-sm' 
                        type="password" 
                        onChange={handleFormChange}
                    />
                    {form.password_confirm.error!==""&&<p className='text-danger'>{form.password_confirm.error}</p>}
                </div>
            </div>
            :<div className="row justify-content-center">
                    <div className="col-12 my-1">
                        <p>
                            The password has been reset
                        </p>
                    </div>
                    <div className="col-auto my-1">
                        <button
                            className='btn-sm btn btn-link'
                            type='button'
                            onClick={handleGoLogin}
                        >
                        Go to login
                        </button>                    
                    </div>
                </div>            
            }            

            <div className="row my-1">
                <div className="col-12">
                    <p>
                        <LocalMessage 
                            message={message.value}
                            type={message.type}
                        />
                    </p>
                </div>
            </div>

            {!goLoginEnabled&&
                <div className="form-group row justify-content-center">
                    <div className="col-auto">
                        <button 
                            disabled={loading}
                            className="btn-sm btn btn-secondary" 
                            type='submit'                        
                            >
                            {!loading?"Reset Password": <span> <i className="fa fa-spinner fa-spin"></i> loading...</span> }
                        </button>
                    </div>
                </div>
            }
        </form>        
    );
}

export default RecoverPasswordForm;
