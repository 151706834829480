const FormatedRate = (props) => {
    const {
        children=0
    } = props
    return (        
        children.toFixed(1)        
    );
}

export default FormatedRate;
